import { Component, OnInit, ViewChild} from '@angular/core';
import { MDBModalService, MDBModalRef, ModalDirective } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import { PopupService } from 'src/app/_clusters/services/popup.service';
import * as _ from 'underscore';
import { EvaluationStep2ModalComponent } from '../evaluation-step2-modal/evaluation-step2-modal.component';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';
@Component({
  selector: 'app-eva-confirmation',
  templateUrl: './eva-confirmation.component.html',
  styleUrls: ['./eva-confirmation.component.scss']
})
export class EvaConfirmationComponent implements OnInit {
  // @ViewChild(ModalDirective) modal: ModalDirective;
  // modalRef: MDBModalRef;
  // modalRef2: MDBModalRef;
  getDashboardMetaDetailsObserver = this.gameplanService.getDashboardMetaDetailsObserver.asObservable();
  evalnts_status: any = 0;
  evalOption = [];
  getEvalObserver = this.gameplanService.getEvalObserver.asObservable();
  gameplanCurrentStatus = 0;
  evaluationModalStatus: any;
  studentDetails: Observable<Array<any>>;
  userData: any = {};
  testStatus: any = false;
  constructor(public modalRef: MDBModalRef,public modalRef2: MDBModalRef, private moadalService: MDBModalService, private popupService: PopupService, private store: Store<any>, private gameplanService: GamePlanService) { }
  ngOnInit() {

    this.store.dispatch(new LoadStudentAction());
    this.store.select((store: any) => store.student.student_details).subscribe((data: any) => {
      this.userData = data;
    });

    this.gameplanService.getevalConfirmation();
    // this.tempValue = "test";
    this.getEvalObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.evalOption = res;
        // //// console.log('this.idArray', this.evalOption);
      }
      // this.evalOption;
    });
    this.gameplanService.getDashboardDetails();
    this.getDashboardMetaDetailsObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.gameplanCurrentStatus = res[0]?.status.gameplan_current_status;

        //// console.log('detailsMetaDashboard', this.gameplanCurrentStatus);
      }
    });
  }
  hide() {
    this.moadalService.hide(1);
  }
  updateEducationMandate(value) {
    const statusvalue = this.evalnts_status;
    var valArray = [1,9,13];
      this.evaluationModalStatus = true;
      this.gameplanService.evalConfirmationPopup({ gameplan_current_status: this.evalnts_status }).subscribe((res: any) => {
        if (res && res.status) {
          // this.moadalService.hide(1);
          this.gameplanService.getMainProgressReport({ type: '' });
          //// console.log('modal', res);
          this.gameplanService.getDashboardDetails();
          this.gameplanService.getNewEvaluation();

          // window.location.reload();

          if(!(valArray.includes(this.evalnts_status)))
          { 
            this.testStatus = true;
            this.evaluationModalStatus = false;
            this.openEvaluationModalStep2Modal();
            this.modalRef.hide();

          }
          else{this.moadalService.hide(1);}



        } else {
          this.popupService.error('Success', res.message);
        }
      });
    // }

  }
  onChange(event) {
    this.evalnts_status = parseInt(event.target.defaultValue);
  }

    openEvaluationModalStep2Modal() {
      this.modalRef2 = this.moadalService.show(EvaluationStep2ModalComponent, {
        backdrop: true,
        keyboard: false,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: 'modal-dialog modal-lg',
        containerClass: 'fade right mt-5',
        animated: true,
        data: { gameplanStatus : this.evalnts_status, userData : this.userData }
      });
    }
}
