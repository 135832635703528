import { FooterModule } from './footer/footer.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginComponent } from './pre-login.component';
import { AicpaHeaderComponent } from './aicpa-header/aicpa-header.component';
import { MilesHeaderComponent } from './miles-header/miles-header.component';
import { RouterModule } from '@angular/router';
import {
  ModalModule, SidenavModule, NavbarModule, PreloadersModule, ProgressbarModule,
  ButtonsModule, AccordionModule, PopoverModule, DropdownModule, TooltipModule, WavesModule, BadgeModule, MDBBootstrapModulesPro, CardsModule
} from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { QuestionsReducer } from 'src/app/_store/reducers/question.reducers';
import { QuestionEffects } from 'src/app/_store/effects/question.effects';
import { CMAQuestionEffects } from 'src/app/_store/effects/cma.question.effects';
import { CountdownModule } from 'ngx-countdown';
// import { FeedbackModule } from 'projects/cpa/src/app/pages/modal-pages/feedback/feedback.module';
import { LogsModalModule } from 'projects/cpa/src/app/pages/modal-pages/logs-modal/logs-modal.module';
import { CalulatorModule } from 'projects/cpa/src/app/pages/modal-pages/calulator/calulator.module';
import { GameplanModalModule } from 'projects/cpa/src/app/pages/modal-pages/gameplan-modal/gameplan-modal.module';
import { IonicBlankComponent } from './ionic-blank.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClipboardModule } from '@angular/cdk/clipboard';
// import { FeedbackModule } from 'projects/cpa/src/app/pages/modal-pages/feedback/feedback.module';
import { FeedbackModule } from 'projects/jobs/src/app/pages/feedback/feedback.module';
import { JainHeaderComponent } from './jain-header/jain-header.component';
import { CMAJainHeaderComponent } from './cma-jain-header/cma-jain-header.component';
import { MocktestHeaderComponent } from './mocktest-header/mocktest-header.component';
import { CustomDerectiveModule } from '../_clusters/custom-derective/custom-derective.module';
import { JobsPopupComponent } from '../../../projects/jobs/src/app/common/jobs-popup/jobs-popup.component';
import { JobsPopupModule } from '../../../projects/jobs/src/app/common/jobs-popup/jobs-popup.module';
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    PreLoginComponent,
    AicpaHeaderComponent,
    MilesHeaderComponent,
    IonicBlankComponent,
    JainHeaderComponent,
    CMAJainHeaderComponent,
    MocktestHeaderComponent
  ],
  imports: [
    CommonModule,
    CountdownModule,
    FooterModule,
    RouterModule, CalulatorModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    LogsModalModule,
    GameplanModalModule,
    PreloadersModule,
    ProgressbarModule,
    ModalModule,
    SidenavModule,
    NavbarModule,
    ButtonsModule,
    AccordionModule,
    FeedbackModule,
    PopoverModule,
    DropdownModule,
    TooltipModule,
    WavesModule,
    NgSelectModule,
    ClipboardModule,
    DragDropModule,
    // MDBBootstrapModulesPro.forRoot(),
    StoreModule.forFeature('question', QuestionsReducer),
    EffectsModule.forFeature([QuestionEffects, CMAQuestionEffects]),
    CustomDerectiveModule,
    JobsPopupModule
  ],
  exports: [
    PreLoginComponent,
    AicpaHeaderComponent,
    MilesHeaderComponent,
    MocktestHeaderComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule { }
