import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { McqsService } from 'src/app/_clusters/services/mcqs.service';
import { Store } from '@ngrx/store';
import { TabsetComponent, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { UserRequested } from 'src/app/_store/actions/auth.actions';
import { CalulatorComponent } from 'projects/cpa/src/app/pages/modal-pages/calulator/calulator.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { JainMcomCourseService } from 'src/app/_clusters/services/cma-jain-mcom-course.service';

@Component({
  selector: 'app-jain-header',
  templateUrl: './cma-jain-header.component.html',
  styleUrls: ['./cma-jain-header.component.scss']
})
export class CMAJainHeaderComponent implements OnInit {

  @Input() data: any;
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  showOption = 0;
  session_id = '';
  mcqQuestionList = [];
  showNext = false;
  showSubmit = true;
  bookmark = [];
  wctMode = false;
  questionIndex = 0;
  activeClass = 0;
  wctSessionId = '';
  // wctQuestion = this.mcqService.wctQuestionList.asObservable();
  attemptQuestion = [];
  wctque = [];
  subjectId: any;
  mainTopicId = [];
  countdown = 0;
  minute = 0;
  hours = 0;
  isMobile: boolean;
  subjectName = '';
  examProgressBar: any;
  modalRef: MDBModalRef;
  showCal = true;
  isCalModal = false;
  assignment_number:any = '';
  exam_assignment = this.jainMcomCourseService.exam_assignment.asObservable();
  practice_assignment = this.jainMcomCourseService.practice_assignment.asObservable();
  sessionChapter = this.jainMcomCourseService.sessionChapter.asObservable();
  practice_assignment_flag: any = false;
  exam_assignment_flag: any = false;
  sessionChapterValue: any = '';
  mcomSessionResultObservable = this.jainMcomCourseService.mcomSessionResultObservable.asObservable();
  mcomSessionResultMetadataObservable = this.jainMcomCourseService.mcomSessionResultMetadataObservable.asObservable();
  isError: boolean = false;
  constructor(
    public route: ActivatedRoute, private modalService: MDBModalService,
    public router: Router, private store: Store<any>, private deviceService: DeviceDetectorService,
    private jainMcomCourseService :JainMcomCourseService) { }

  ngOnInit() {
    
    this.sessionChapter.subscribe((res2 : any) => {
      this.sessionChapterValue = res2;
    });


    this.isMobile = this.deviceService.isMobile();

    setInterval(() => {
      if (this.countdown === 59) {
        this.countdown = 0;
        this.minute++;
        if (this.minute === 60) {
          this.minute = 0;
          this.hours++;
        }
      }
      this.countdown++;
    }, 1000);


    // this.submitExam();


    this.exam_assignment.subscribe((res1 : any) => {
      this.session_id = res1;
     });

     this.practice_assignment.subscribe((res2 : any) => {
      this.session_id = res2;
     });
   
  }
  option(index) {
    this.showOption = index;
  }
  
 
  nextQuestion() {
    this.questionIndex++;
    this.showNext = false;
    this.activeClass = this.questionIndex;
  }
  activePng1(index) {
    if (this.activeClass === index) {
      return true;
    } else {
      return false;
    }
  }
  click(id) {
    document.getElementById(id).style.display === 'block' ? document.getElementById(id).style.display = 'none' : document.getElementById(id).style.display = 'block';
  }
  
  goToQuestion(i) {
    this.jainMcomCourseService.questionId.next(i);
  }
  
  submitExam() {
    if(this.session_id){
      this.jainMcomCourseService.getMcomSessionResults({'session_id': this.session_id});
      this.mcomSessionResultObservable.subscribe((result : any)=>{
        if(result){
          this.isError = false;
          this.router.navigate(['/cma/lms/jain-mcom/test-report',this.session_id]);
        }
       else{
        this.isError = true;
       }
      });
     
    }
else{ this.isError = true;}
    
  
  }

  quit() {
    this.router.navigate(['/cma/lms/jain-mcom']);
  }

  openWindow() {
    window.open('https://factory.mileseducation.com/Calculator/Calculator.html',
      '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=576,height=300');
  }
  openCal() {
    this.modalRef = this.modalService.show(CalulatorComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-lg modal-side modal-bottom-right ',
      containerClass: '',
      animated: true,
    });
  }

}
