<!-- TESTLET Top Header -->
<section class="mocktest_top_header" *ngIf="!isPolicyPage">
    <div class="exam-section-name">Auditing and Attestation</div>
    <div class="header-ribbon">
        <ul class="testlet-position">
            <li>
                <div title="Simulation Questions">
                    <label class="testletobjective-inprogress" id="testlet1"><span></span>Testlet 1</label>
                </div>
            </li>
            <li>
                <div title="Simulation Questions">
                    <label class="testletobjective-upcoming" id="testlet1"><span></span>Testlet 2</label>
                </div>
            </li>
            <li>
                <div title="Simulation Questions">
                    <label class="testletobjective-upcoming" id="testlet1"><span></span>Testlet 3</label>
                </div>
            </li>
            <li>
                <div title="Simulation Questions">
                    <label class="testletobjective-upcoming" id="testlet1"><span></span>Testlet 4</label>
                </div>
            </li>
            <li>
                <div title="Simulation Questions">
                    <label class="testletobjective-upcoming" id="testlet1"><span></span>Testlet 5</label>
                </div>
            </li>

        </ul>
    </div>
</section>

<!-- Mocktest Policy Header -->
<section class="mocktest_header">
    <div class="exam_timer">
        <div class="exam_time_container">
            <div class="timer_numbers" id="timer"><countdown [config]="{leftTime: timer }" (event)="onLeftTime($event)"></countdown></div>
            <div class="timer_label" style="margin-top: 35px;">EXAM TIME REMAINING</div>
        </div>
    </div>
    <ng-container *ngIf="!isPolicyPage">
        <!-- TESTLET Header Tools -->
        <div class="tools-container">
            <div class="exam-tools">
                <div class="exam-tools-align">
                    <div class="exam-tools-containers calc" title="Click to open the calculator">
                        <span class="tools-icon"></span><br>
                        <label style="margin-top:5px; font-weight:200!important;">CALC.</label>
                    </div>
                </div>

                <div class="exam-tools-align">
                    <div class="exam-tools-containers excel" title="Click to open the Excel">
                        <span class="tools-icon"></span><br>
                        <label style="margin-top:5px; font-weight:200!important;">EXCEL</label>
                    </div>
                </div>

                <div class="exam-tools-align" style="width: 100px;">
                    <div class="exam-tools-containers overview" title="Click to open the overview">
                        <span class="tools-icon"></span><br>
                        <label style="margin-top:5px; font-weight:200!important;">OVERVIEW</label>
                    </div>
                </div>

                <!-- <div class="exam-tools-align">
                    <div class="exam-tools-containers help" title="Click to open the help">
                        <span class="tools-icon"></span><br>
                        <label style="margin-top:5px; font-weight:200!important;">HELP</label>
                    </div>
                </div> -->

            </div>
        </div>

        <!-- TESTLET Header Submit -->
        <div class="submit-testlet-button">
            <div class="" title="Submit this testlet and continue the exam">
                <button class="btn primary m-0" (click)="submittestletModal.show()">SUBMIT<br>TESTLET</button>
            </div>
        </div>
    </ng-container>
</section>

<router-outlet></router-outlet>

<!-- Submit Testlet Modal -->
<div mdbModal #submittestletModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="max-width: 743px;">
        <div class="modal-content" style="width: 743px; border-left: 5px solid #d74c15;border-radius: 6px !important;">
            <div class="modal-header" style="padding: 25px 0px;">
                <button type="button" class="close pull-right" style="top: 10px;" aria-label="Close"
                    (click)="submittestletModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 15px 25px 25px 25px;">
                <div class="d-flex" style="border-bottom: 1px solid #4d7381; padding: 5px 0px 15px">
                    <span class="icon"><img src="./assets/img/mocktest/icon1.png"
                            style="width: 32px; height: 32px;"></span>
                    <p class="modal_heading_text">You have skipped one or more questions in this testlet.</p>
                </div>
                <div class="navigation_box">
                    <span style="font-size: 16px;">Navigate To:</span>
                    <ul style="margin-top: -21px;padding-left: 89px;margin-bottom: 0px;">
                        <li><a href="#" class="getques 1" id="">1</a></li>
                        <li><a href="#" class="getques 2" id="">2</a></li>
                        <li><a href="#" class="getques 3" id="">3</a></li>
                        <li><a href="#" class="getques 4" id="">4</a></li>
                        <li><a href="#" class="getques 5" id="">5</a></li>
                        <li><a href="#" class="getques 6" id="">6</a></li>
                        <li><a href="#" class="getques 7" id="">7</a></li>
                        <li><a href="#" class="getques 8" id="">8</a></li>
                        <li><a href="#" class="getques 9" id="">9</a></li>
                        <li><a href="#" class="getques 10" id="">10</a></li>
                        <li><a href="#" class="getques 11" id="">11</a></li>
                        <li><a href="#" class="getques 12" id="">12</a></li>
                        <li><a href="#" class="getques 13" id="">13</a></li>
                        <li><a href="#" class="getques 14" id="">14</a></li>
                        <li><a href="#" class="getques 15" id="">15</a></li>
                        <li><a href="#" class="getques 16" id="">16</a></li>
                        <li><a href="#" class="getques 17" id="">17</a></li>
                        <li><a href="#" class="getques 18" id="">18</a></li>
                        <li><a href="#" class="getques 19" id="">19</a></li>
                        <li><a href="#" class="getques 20" id="">20</a></li>
                        <li><a href="#" class="getques 21" id="">21</a></li>
                        <li><a href="#" class="getques 22" id="">22</a></li>
                        <li><a href="#" class="getques 23" id="">23</a></li>
                        <li><a href="#" class="getques 24" id="">24</a></li>
                        <li><a href="#" class="getques 25" id="">25</a></li>
                        <li><a href="#" class="getques 26" id="">26</a></li>
                        <li><a href="#" class="getques 27" id="">27</a></li>
                        <li><a href="#" class="getques 28" id="">28</a></li>
                        <li><a href="#" class="getques 29" id="">29</a></li>
                        <li><a href="#" class="getques 30" id="">30</a></li>
                        <li><a href="#" class="getques 31" id="">31</a></li>
                        <li><a href="#" class="getques 32" id="">32</a></li>
                        <li><a href="#" class="getques 33" id="">33</a></li>
                        <li><a href="#" class="getques 34" id="">34</a></li>
                        <li><a href="#" class="getques 35" id="">35</a></li>
                        <li><a href="#" class="getques 36" id="">36</a></li>
                        <li><a href="#" class="getques 37" id="">37</a></li>
                    </ul>
                </div>
                <p style="font-size: 15px;margin-top: 25px;text-align: justify;">Click Submit Testlet to confirm your
                    submission, or click on a question number above to complete your work. You will not be able to
                    return to this testlet after you click Submit Testlet.</p>
            </div>
            <div class="modal-footer" style="padding: 15px; border-top: 1px solid #e5e5e5; background-color: #f5f5f5;">
                <button class="secondary">Return To Testlet</button>
                <button class="primary" id="begin_mocktest"
                    style="border-radius: 0px !important; padding-top: 8px; padding-bottom: 8px;"
                    (click)="submittestletModal.hide()" mdbWavesEffect>Submit Testlet</button>
            </div>
        </div>
    </div>
</div>
