import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalulatorComponent } from "./calulator.component";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { RouterModule } from "@angular/router";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [CalulatorComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    RouterModule,
    DragDropModule,
  ],
  exports: [CalulatorComponent],
  entryComponents: [CalulatorComponent],
})
export class CalulatorModule {}
