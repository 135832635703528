<!-- <div class="modal-header mdb-color text-light py-1">
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="hide()" style="font-size:2rem;">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100">
    <i class="fas fa-university mr-2"></i> Evaluation Agency and Stateboard
  </h4>
</div> -->
<div class="modal-body px-2 py-3" style="height: 400px; overflow-y: auto;">
  <div class="row mx-0">
    <div class="col-12 my-3">
      <label class="mr-2 degree-label my-auto"><i class="fad fa-landmark-alt my-auto mr-1" style="font-size: 1.2rem;"></i>Evaluation Agency - State Board: <span class="mt-0 text-danger">*</span> </label>
      <ng-select [items]="agencyAdminArray"
      bindLabel="title"
      bindValue="evaluation_doc_id"
      placeholder = "Choose Agency - State Board"
      [(ngModel)]="agency_state_board"
      (change)="getAgencyStateboard($event)" [disabled]="showNtsBlock" required>
</ng-select>
    </div>
<div class="col-12 mb-3">

    <!-- Uploading Progress Begins -->
    <ng-container *ngIf="showProgress">
      <table mdbTable borderless="true" striped="true" hover="true" small="true">
        <thead class="black white-text">
          <tr>
            <th>File Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td><small class="small"><strong>{{ item?.file?.name }}</strong></small></td>
            <td *ngIf="uploader.options.isHTML5">
              <small class="small">{{ item?.file?.size/978/1024 | number:'.1' }} MB</small>
            </td>
            <td *ngIf="uploader.options.isHTML5">
              <div class="progress m-0">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
              </div>
            </td>
            <td>
              <button type="button" mdbBtn color="warning" size="sm" mdbWavesEffect (click)="item.cancel()" [disabled]="!item.isUploading"><i class="fa fa-times mr-1"></i> Cancel</button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <!-- Uploading Progress Ends -->
  <ng-container *ngIf="step3Status || step4Status">
    <div class="d-flex flex-column mt-2 w-100">
      <ng-container *ngIf="!showNtsBlock">
        <div class="d-flex">
          <label class="mr-2 degree-label my-auto"><i class="fad fa-folder-open mr-3"></i>Upload Evaluation Report <span class="text-danger">*</span>: </label>
          <div class="upload_box d-flex">
            <div class="image-upload-wrap" style="cursor: pointer !important;">
              <input type="file" accept=".jpeg,.png,.jpg,.pdf,.docx" id="attachment1" multiple name="attachment1" ng2FileSelect size="sm" mdbBtn color="primary" class="mt-0 ml-0 mr-0" mdbWavesEffect [uploader]="uploader" (change)="currentUploading(1,$event)" required>
            </div>
          </div>
        </div>
        <!-- ########## Evaluation Documents Begins ########## -->
        <div class="w-100 ml-auto file_box my-2" *ngIf="showAttached && viewFileArrayEvaluation.length">
          <div class="p-2 d-inline-block" *ngFor="let item of viewFileArrayEvaluation; let i=index">
            <div class="thumbnail-doc" *ngIf="item.format=='png' || item.format=='jpeg' || item.format=='jpg'">
              <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
              <a [href]="item.path" target="_blank">
                <img [src]="item.path" class="w-100 my-2" style="height: 90px;object-fit: cover;">
                <p class="py-2">{{item.name | ellipsis:[15,'...']}}</p>
                <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
              </a>
            </div>

            <div class="thumbnail-doc" *ngIf="item.format=='pdf'">

              <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
              <a [href]="item.path" target="_blank">
                <div class="pdf my-2"><i class="fas fa-file-pdf"></i></div>
                <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
              </a>
            </div>

            <div class="thumbnail-doc" *ngIf="item.format!='pdf' && item.format!='png' && item.format!='jpeg' && item.format!='jpg'">

              <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
              <a [href]="item.path" target="_blank">
                <div class="word my-2"><i class="fas fa-file-word"></i></div>
                <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
              </a>
            </div>

          </div>
        </div>
      </ng-container>
        <!-- ########### Evaluation Documents Ends ########### -->
        <ng-container *ngIf="step4Status && showNtsBlock">
          <div class="d-flex flex-column border-top border-bottom my-3 py-2">
          <label class="mr-2 degree-label my-auto">Which subject NTS you have recieved?</label>

          <ng-container *ngFor="let item of idArray; let i= index;">
            <mdb-checkbox [default]="true" value="{{item.id}}" [checked]="item.isChecked" (change)="onNtsChange($event, i)">{{item.name}}</mdb-checkbox>
          </ng-container>
          </div>

          <div class="d-flex">
            <label class="mr-2 degree-label my-auto"><i class="fad fa-folder-open mr-3"></i>Upload NTS Report <span class="text-danger">*</span>: </label>
            <div class="upload_box d-flex">
              <div class="image-upload-wrap" style="cursor: pointer !important;">
                <input type="file" accept=".jpeg,.png,.jpg,.pdf,.docx" id="attachment2" multiple name="attachment2" ng2FileSelect size="sm" mdbBtn color="primary" class="mt-0 ml-0 mr-0" mdbWavesEffect [uploader]="uploader" (change)="currentUploading(2,$event)" required>
              </div>
            </div>
          </div>
          <!-- ########## Nts Documents Begins ########## -->
          <div class="w-100 ml-auto file_box my-2" *ngIf="showAttached && viewFileArrayNts.length">
            <div class="p-2 d-inline-block" *ngFor="let item of viewFileArrayNts; let i=index">
              <div class="thumbnail-doc" *ngIf="item.format=='png' || item.format=='jpeg' || item.format=='jpg'">
                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <img [src]="item.path" class="w-100 my-2" style="height: 90px;object-fit: cover;">
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}}</p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

              <div class="thumbnail-doc" *ngIf="item.format=='pdf'">

                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <div class="pdf my-2"><i class="fas fa-file-pdf"></i></div>
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

              <div class="thumbnail-doc" *ngIf="item.format!='pdf' && item.format!='png' && item.format!='jpeg' && item.format!='jpg'">

                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <div class="word my-2"><i class="fas fa-file-word"></i></div>
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

            </div>
          </div>
          <!-- ########### Nts Documents Ends ########### -->
          <div class="d-flex">
            <label class="mr-2 degree-label my-auto"><i class="fad fa-folder-open mr-3"></i>Upload Passport copy</label>
            <div class="upload_box d-flex">
              <div class="image-upload-wrap" style="cursor: pointer !important;">
                <input type="file" accept=".jpeg,.png,.jpg,.pdf,.docx" id="attachment3" multiple name="attachment3" ng2FileSelect size="sm" mdbBtn color="primary" class="mt-0 ml-0 mr-0" mdbWavesEffect [uploader]="uploader" (change)="currentUploading(3,$event)">
              </div>
            </div>
          </div>
          <!-- ########## Nts Passport Documents Begins ########## -->
          <div class="w-100 ml-auto file_box my-2" *ngIf="showAttached && viewFileArrayPassport.length">
            <div class="p-2 d-inline-block" *ngFor="let item of viewFileArrayPassport; let i=index">
              <div class="thumbnail-doc" *ngIf="item.format=='png' || item.format=='jpeg' || item.format=='jpg'">
                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <img [src]="item.path" class="w-100 my-2" style="height: 90px;object-fit: cover;">
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}}</p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

              <div class="thumbnail-doc" *ngIf="item.format=='pdf'">

                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <div class="pdf my-2"><i class="fas fa-file-pdf"></i></div>
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

              <div class="thumbnail-doc" *ngIf="item.format!='pdf' && item.format!='png' && item.format!='jpeg' && item.format!='jpg'">

                <span (click)=removeAttachment(i)><i style="right: 2px; top: 0px;" class="fas fa-times close_icon text-danger" aria-hidden="true"></i></span>
                <a [href]="item.path" target="_blank">
                  <div class="word my-2"><i class="fas fa-file-word"></i></div>
                  <p class="py-2">{{item.name | ellipsis:[15,'...']}} </p>
                  <p class="py-2" style="color: #717171;">({{item.size/100}}kb)</p>
                </a>
              </div>

            </div>
          </div>
          <!-- ########### Nts Passport Documents Ends ########### -->
        </ng-container>
    </div>





  </ng-container>
</div>

  </div>
</div>
<div class="modal-footer p-0">
  <button *ngIf="!showNtsBlock" type="button" mdbBtn color="primary" class="relative waves-light btn btn-primary btn-md" mdbWavesEffect (click)="sendEvaluationAdviceOnly()">Update Evaluation</button>
  <button *ngIf="showNtsBlock" type="button" mdbBtn color="primary" class="relative waves-light btn btn-primary btn-md" mdbWavesEffect (click)="updateNTSDocuments()">Update NTS</button>
</div>
