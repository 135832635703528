import { Component, OnInit, ViewChild } from '@angular/core';
import { IMyOptions, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Store } from '@ngrx/store';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import { MonthService } from 'src/app/_clusters/documents/month.service';
import Swal from 'sweetalert2';
import { UserRequested } from 'src/app/_store/actions/auth.actions';
import * as _ from 'underscore';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EvaConfirmationComponent } from '../../_my-space/gameplantool/common-modal/eva-confirmation/eva-confirmation.component';
import { PopupService } from 'src/app/_clusters/services/popup.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-gameplan-modal',
  templateUrl: './gameplan-modal.component.html',
  styleUrls: ['./gameplan-modal.component.scss']
})
export class GameplanModalComponent implements OnInit {
  @ViewChild('audForm') audForm: any;
  @ViewChild('becForm') becForm: any;
  @ViewChild('farForm') farForm: any;
  @ViewChild('regForm') regForm: any;
  isMobile: boolean;
  isTablet: boolean;
  subjectId: any;
  isFilter = false;
  studentDetails: any = {};
  statusDD = this.gamePlanService.getStatusSubject.asObservable();
  statusList = [];
  audData: any = { id: 0, subject_id: 101 };
  becData: any = { id: 0, subject_id: 102 };
  farData: any = { id: 0, subject_id: 103 };
  regData: any = { id: 0, subject_id: 104 };
  year = new Date().getFullYear();
  switchId = null;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDate = new Date().getDate();
  DateValidation = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    monthLabels: {
      1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10:
        'Oct', 11: 'Nov', 12: 'Dec'
    },
    dateFormat: 'dd-mmm-yyyy',
    minYear: new Date().getFullYear(),
    maxYear: 2025,
    disableUntil: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    }
  };
  public passedDateOption: IMyOptions = {
    closeAfterSelect: true,
    monthLabels: {
      1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10:
        'Oct', 11: 'Nov', 12: 'Dec'
    },
    dateFormat: 'dd-mmm-yyyy',
    minYear: 1970,
    maxYear: new Date().getFullYear(),
    disableSince: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    }
  };
  options = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
  ];
  scoreArray = [];
  getEvalObserver = this.gamePlanService.getEvalObserver.asObservable();
  evalOption = [];
  evalDataVal: any;
  evalData: any = {};
  evalDataId: any;
  editStatus: any;
  getStudentGameplanStatusDataObserver = this.gamePlanService.getStudentGameplanStatusDataObserver.asObservable();
  getStudentGameplanStatusMetaDataObserver = this.gamePlanService.getStudentGameplanStatusMetaDataObserver.asObservable();
  statusMessagesObserver = this.gamePlanService.getstudentdetailsObserver.asObservable();
  modalRef2 : MDBModalRef;

  subjectStatus:any = false;
  // #################### Gameplane Status Block Begins ##################### //
  getDashboardMetaDetailsObserver = this.gamePlanService.getDashboardMetaDetailsObserver.asObservable();
  evalnts_status: any = 0;
  // evalOption = [];
  // getEvalObserver = this.gamePlanService.getEvalObserver.asObservable();
  gameplanCurrentStatus = 0;
  gameplanPreviousStatus = 0;
  evaluationModalStatus: any;
  userData: any = {};
  testStatus: any = false;
  showEvaluation: any = false;
  evalDataStatus: any;
  Url = `${environment.apiUrl}`;
  // ##################### Gameplane Status Block Ends ###################### //

  constructor(
    private modalRef: MDBModalRef,
    public monthService: MonthService,
    private store: Store<any>,
    public gamePlanService: GamePlanService,
    private deviceService: DeviceDetectorService,
    private modalService: MDBModalService,private http: HttpClient,
    private popupService: PopupService) { }
  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.store.select((store: any) => store.student.student_details).subscribe(data => {
      if (data) {
        this.studentDetails = data;
        // console.log(data);
      }
    });
    this.gamePlanService.getstatusdropdown();
    this.statusDD.subscribe(data => {
      this.statusList = data;
    });
    this.gamePlanService.getExam().subscribe((res: any) => {
      if (res && res.status) {
        if (!_.isEmpty(res.data)) {
          res.data.filter(el => {
            if (el.subject_id == 101) {
              this.audData.date_of_exam = el.date_of_exam;
              this.audData.exam_status = el.exam_status;
              this.audData.id = el.id;
              this.audData.score = el.score + '';
            } else if (el.subject_id == 102) {
              this.becData.date_of_exam = el.date_of_exam;
              this.becData.exam_status = el.exam_status;
              this.becData.id = el.id;
              this.becData.score = el.score + '';
            } else if (el.subject_id == 103) {
              this.farData.date_of_exam = el.date_of_exam;
              this.farData.exam_status = el.exam_status;
              this.farData.id = el.id;
              this.farData.score = el.score + '';
            }
            else if (el.subject_id == 104) {
              this.regData.date_of_exam = el.date_of_exam;
              this.regData.exam_status = el.exam_status;
              this.regData.id = el.id;
              this.regData.score = el.score + '';
            }
          });
        }
      }
    });
    for (let index = 75; index <= 99; index++) {
      this.scoreArray.push(index + '');
    }
    this.getGameplanStausDropdown();
    this.getStudentGameplanStatus();

  //################################################//
      this.gamePlanService.getDashboardDetails();
      this.getDashboardMetaDetailsObserver.subscribe((res: any) => {
        if (!_.isEmpty(res)) {
          this.gameplanCurrentStatus = res[0]?.status.gameplan_current_status;
          this.gameplanPreviousStatus = res[0]?.status?.previous_gameplan_status;
          if (this.gameplanCurrentStatus != 6 && this.gameplanCurrentStatus != 7 && this.gameplanCurrentStatus != 8 && this.gameplanCurrentStatus != 11 && this.gameplanCurrentStatus != 12 && this.gameplanCurrentStatus != 13 
            && this.gameplanPreviousStatus != 6 && this.gameplanPreviousStatus != 7 && this.gameplanPreviousStatus != 8 && this.gameplanPreviousStatus != 11 && this.gameplanPreviousStatus != 12 && this.gameplanPreviousStatus != 13) {
            this.statusList = [{
              exam_status_id: 1,
              exam_status_type: "Preparing for Exam [Yet to Appear]"
            }]
          }
          //// console.log('detailsMetaDashboard', this.gameplanCurrentStatus);
        }
      });
//################################################//
  }
  setDate(row) {
    const months = row.value > 9 ? row.value : '0' + row.value;
    switch (this.switchId) {
      case 1:
        this.audData.date_of_exam = row.month + '-' + this.year;
        break;
      case 2:
        this.becData.date_of_exam = row.month + '-' + this.year;
        break;
      case 3:
        this.farData.date_of_exam = row.month + '-' + this.year;
        break;
      case 4:
        this.regData.date_of_exam = row.month + '-' + this.year;
        break;
      default:
        break;
    }
  }
  continueGameplan() {
    if(this.audData.exam_status == undefined || this.becData.exam_status == undefined || this.farData.exam_status == undefined || this.regData.exam_status == undefined){
      Swal.fire({
        icon: "error",
        title: "please select all your subject status"
      })
    }
    else{
      this.gamePlanService.getcurrentgpstatus1();
    this.gamePlanService.getcurrentgpObserver1.subscribe((respo: any) => {
      var swal_message = "";
        if (this.gameplanCurrentStatus == 9 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 1 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 3 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your are yet to upload your Evaluation Report on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 4 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 5 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 7 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 2 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 10 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
        else if (this.gameplanCurrentStatus == 6 && this.evalData?.studyplanner_status != null && this.evalData?.studyplanner_status != 0) {
          swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
        }
      if(respo[0].pop_up_count == 1 && respo[0].pop_up_alert == 1){
        Swal.fire({
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: '#0069D9',
          cancelButtonColor: '#0069D9',
          confirmButtonText: 'SKIP FOR NOW',
          cancelButtonText: "<a style = 'color:#fff' href = '/lms/mileage/gameplan/dashboard'>GAME PLAN TOOL</a>",
          text: swal_message,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            if (respo[0].pop_up_count == null) {
              respo[0].pop_up_count = 2
            }
            else {
              respo[0].pop_up_count = respo[0].pop_up_count + 1
            }
            var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_count: respo[0].pop_up_count, pop_up_alert: 2, old_gp_status: respo[0].old_gp_status }
            this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {
              this.popupService.success('', "Your status has been updated successfully!");
              this.subjectStatus = true;
              this.editStatus = false;
              this.getStudentGameplanStatus();
              this.ngOnInit();
            });
          }
        })
      }
      else if(respo[0].pop_up_alert == 3){
        Swal.fire({
          icon: "info",
          html: swal_message,
          allowOutsideClick: false,
          confirmButtonColor: '#0069D9',
          confirmButtonText: "<a style = 'color:#fff' href = '/lms/mileage/gameplan/dashboard'>Ok</a>",
        })
      }
      else if((this.evalData?.gameplan_current_status_id == 9 || this.evalData?.gameplan_current_status_id == 1 || this.evalData?.gameplan_current_status_id == 2 || this.evalData?.gameplan_current_status_id == 3 || this.evalData?.gameplan_current_status_id == 4 || this.evalData?.gameplan_current_status_id == 10 || this.evalData?.gameplan_current_status_id == 5)
      && (this.evalData?.studyplanner_id == 9 || this.evalData?.studyplanner_id == 1 || this.evalData?.studyplanner_id == 2 || this.evalData?.studyplanner_id == 3 || this.evalData?.studyplanner_id == 4 || this.evalData?.studyplanner_id == 10 || this.evalData?.studyplanner_id == 5 || this.evalData?.studyplanner_id == null)
       && (this.audData.exam_status > 1 || this.becData.exam_status > 1 || this.farData.exam_status > 1 || this.regData.exam_status > 1)){
        Swal.fire({
          icon: "info",
          text: "Note that there is a mis-match between your Gameplan Status and your Subject Status. Please check and update your Subject Status!"
        })
      }
      else if(this.evalData?.gameplan_current_status == "Received NTS; Yet to apply for exams" || 
      this.evalData?.gameplan_current_status == "Already appeared for 1 or more exams; Yet to pass all 4" || 
      this.evalData?.gameplan_current_status == "Passed all 4 exams; Yet to receive license advice from Miles" || 
      this.evalData?.gameplan_current_status == "Received license advice from Miles; License application WIP" || 
      this.evalData?.gameplan_current_status == "Received CPA license" || 
      this.evalData?.gameplan_current_status == "Sent Education documents to Miles; Yet to receive Eligibility" ||
      this.evalData?.studyplanner_status == "Received NTS; Yet to apply for exams" ||
      this.evalData?.studyplanner_status == "Already appeared for 1 or more exams; Yet to pass all 4" || 
      this.evalData?.studyplanner_status == "Passed all 4 exams; Yet to receive license advice from Miles" || 
      this.evalData?.studyplanner_status == "Received license advice from Miles; License application WIP" || 
      this.evalData?.studyplanner_status == "Received CPA license" || 
      this.evalData?.studyplanner_status == "Sent Education documents to Miles; Yet to receive Eligibility"
      ){
        if((this.evalData?.gameplan_current_status == "Received NTS; Yet to apply for exams" || this.evalData?.studyplanner_status == "Received NTS; Yet to apply for exams") && (this.audData.exam_status == 1 && this.becData.exam_status == 1 && this.farData.exam_status == 1 && this.regData.exam_status == 1)){
          Swal.fire({
            icon: "info",
            title: "Kindly update the status of the subject for which you received the NTS."
          })
        }
        else if((this.evalData?.gameplan_current_status == "Already appeared for 1 or more exams; Yet to pass all 4" || this.evalData?.studyplanner_status == "Already appeared for 1 or more exams; Yet to pass all 4") && (this.audData.exam_status < 4 && this.becData.exam_status < 4 && this.farData.exam_status < 4 && this.regData.exam_status < 4)){
          Swal.fire({
            icon: "info",
            title: "Kindly update the exam status for the subject/subjects that you have appeared."
          })
        }
        else if((this.evalData?.gameplan_current_status == "Received NTS; Yet to apply for exams" || this.evalData?.studyplanner_status == "Received NTS; Yet to apply for exams") && ((this.evalData?.gameplan_current_status_id <= 6 || this.evalData?.studyplanner_id >= 6) && (this.evalData?.gameplan_current_status_id != 9 && this.evalData?.studyplanner_id != 9) && (this.evalData?.gameplan_current_status_id != 10 && this.evalData?.studyplanner_id != 10)) && (this.audData.exam_status > 3 || this.becData.exam_status > 3 || this.farData.exam_status > 3 || this.regData.exam_status > 3)){
          Swal.fire({
            icon: "info",
            title: "Note that there is a mis-match between your Gameplan Status and your Subject Status. Please check and update your Subject Status!"
          })
        }
        else if(this.evalData?.gameplan_current_status == "Passed all 4 exams; Yet to receive license advice from Miles" || 
        this.evalData?.gameplan_current_status == "Received license advice from Miles; License application WIP" || 
        this.evalData?.gameplan_current_status == "Received CPA license" || 
        this.evalData?.gameplan_current_status == "Sent Education documents to Miles; Yet to receive Eligibility" || 
        this.evalData?.studyplanner_status == "Passed all 4 exams; Yet to receive license advice from Miles" || 
        this.evalData?.studyplanner_status == "Received license advice from Miles; License application WIP" || 
        this.evalData?.studyplanner_status == "Received CPA license" || 
        this.evalData?.studyplanner_status == "Sent Education documents to Miles; Yet to receive Eligibility"
        ){
          if(this.evalData?.gameplan_current_status == "Passed all 4 exams; Yet to receive license advice from Miles" && this.audData.exam_status != 4 || this.becData.exam_status != 4 || this.farData.exam_status != 4 || this.regData.exam_status != 4){
            Swal.fire({
              icon: "info",
              title: "Kindly update the exam date and scores for ALL the subjects."
            })
          }
          else if(this.evalData?.gameplan_current_status == "Received license advice from Miles; License application WIP" && this.audData.exam_status != 4 || this.becData.exam_status != 4 || this.farData.exam_status != 4 || this.regData.exam_status != 4){
            Swal.fire({
              icon: "info",
              title: "Kindly update the exam date and scores for ALL the subjects."
            })
          }
          else if(this.evalData?.gameplan_current_status == "Received CPA license" && this.audData.exam_status != 4 || this.becData.exam_status != 4 || this.farData.exam_status != 4 || this.regData.exam_status != 4){
            Swal.fire({
              icon: "info",
              title: "Kindly update the exam date and scores for ALL the subjects."
            })
          }
          else if(this.audData.exam_status < 4 || this.becData.exam_status < 4 || this.farData.exam_status < 4 || this.regData.exam_status < 4){
            Swal.fire({
              icon: "info",
              title: "Kindly update the exam date and scores for ALL the subjects."
            })
          }
          else{
            if (this.audForm.valid && this.becForm.valid && this.farForm.valid && this.regForm.valid) {
              this.gamePlanService.updateExam([
                { ...this.audData },
                { ...this.becData },
                { ...this.farData },
                { ...this.regData }]).subscribe((res: any) => {
                  if (res && res.status) {
                    // console.log(respo, '1');
                    if(respo[0].pop_up_alert == 2){
                      var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 3 }
                      this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                    }
                    else if((respo[0].pop_up_count == 1)){
                      var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 1 }
                      this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                    }
                    Swal.fire({
                      icon: 'success',
                      text: "Your status has been updated successfully!"
                    })
                    this.store.dispatch(new UserRequested());
                    if (this.isFilter) {
                      this.gamePlanService.getstudyplannerFilter(this.subjectId)
                    }
                    this.modalRef.hide();
                    window.location.reload();
                  }else {
                    Swal.fire({
                      icon: 'info',
                      html: res.message + " <b> " + res.metadata[0] + "</b>"
                    })
                    // this.modalRef.hide();
                  }
                })
            } else {
              this.audForm.control.markAllAsTouched();
              this.becForm.control.markAllAsTouched();
              this.farForm.control.markAllAsTouched();
              this.regForm.control.markAllAsTouched();
            }
          }
        }
        else{
          if (this.audForm.valid && this.becForm.valid && this.farForm.valid && this.regForm.valid) {
            this.gamePlanService.updateExam([
              { ...this.audData },
              { ...this.becData },
              { ...this.farData },
              { ...this.regData }]).subscribe((res: any) => {
                if (res && res.status) {
                  // console.log(respo, '2');
                  if(respo[0].pop_up_alert == 2){
                    var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 3 }
                    this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                  }
                  else if((respo[0].pop_up_count == 1)){
                    var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 1 }
                    this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                  }
                  Swal.fire({
                    icon: 'success',
                    text: "Your status has been updated successfully!"
                  })
                  this.store.dispatch(new UserRequested());
                  if (this.isFilter) {
                    this.gamePlanService.getstudyplannerFilter(this.subjectId)
                  }
                  this.modalRef.hide();
                  window.location.reload();
                }else {
                  Swal.fire({
                    icon: 'info',
                    html: res.message + " <b> " + res.metadata[0] + "</b>"
                  })
                  // this.modalRef.hide();
                }
              })
          } else {
            this.audForm.control.markAllAsTouched();
            this.becForm.control.markAllAsTouched();
            this.farForm.control.markAllAsTouched();
            this.regForm.control.markAllAsTouched();
          }
        }
      }
      else{
        if (this.audForm.valid && this.becForm.valid && this.farForm.valid && this.regForm.valid) {
          this.gamePlanService.updateExam([
            { ...this.audData },
            { ...this.becData },
            { ...this.farData },
            { ...this.regData }]).subscribe((res: any) => {
              if (res && res.status) {
                // console.log(respo, '3');
                if(respo[0].pop_up_alert == 2){
                  var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 3 }
                  this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                }
                else if((respo[0].pop_up_count == 1)){
                  var data = { gameplan_current_status: respo[0].gameplan_current_status, pop_up_alert: 1 }
                  this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {})
                }
                Swal.fire({
                  icon: 'success',
                  text: "Your status has been updated successfully!"
                })
                this.store.dispatch(new UserRequested());
                if (this.isFilter) {
                  this.gamePlanService.getstudyplannerFilter(this.subjectId)
                }
                this.modalRef.hide();
                window.location.reload();
              }else {
                Swal.fire({
                  icon: 'info',
                  html: res.message + " <b> " + res.metadata[0] + "</b>"
                })
                // this.modalRef.hide();
              }
            })
        } else {
          this.audForm.control.markAllAsTouched();
          this.becForm.control.markAllAsTouched();
          this.farForm.control.markAllAsTouched();
          this.regForm.control.markAllAsTouched();
        }
      }
    })
    }
  }
  preYear() {
    this.year = this.year - 1;
  }
  nextYear() {
    this.year = this.year + 1;
  }
  audChange() {
    this.audData.date_of_exam = '';
    this.audData.score = '';
  }
  becChange() {
    this.becData.date_of_exam = '';
    this.becData.score = '';
  }
  farChange() {
    this.farData.date_of_exam = '';
    this.farData.score = '';
  }
  regChange() {
    this.regData.date_of_exam = '';
    this.regData.score = '';
  }

  getGameplanStausDropdown(){
    this.gamePlanService.getevalConfirmation();
    this.getEvalObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.evalOption = res;
        // console.log( this.evalOption);
      }
    });
  }

  openStatusModal(){
    this.modalRef2 = this.modalService.show(EvaConfirmationComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered modal-lg',
      containerClass: 'modal fade overflow-y',
      animated: true,

    });
  }

  // #################### Gameplane Status Block Begins ##################### //

  getStudentGameplanStatus(){
    const param = { 'emailId' : this.studentDetails.email }
    this.gamePlanService.getStudentGameplanStatus(param);
    this.getStudentGameplanStatusMetaDataObserver.subscribe(res => {
      this.evalDataVal =res;
      this.editStatus = res?false:true;
    });
    this.getStudentGameplanStatusDataObserver.subscribe(res2 => {
      this.evalData = res2[0];
      this.evalDataId = this.evalData?.gameplan_current_status_id;
      if(this.evalData?.studyplanner_status != null){
        this.evalDataStatus = this.evalData?.studyplanner_status
      }
      else{
        this.evalDataStatus = this.evalData?.gameplan_current_status;
      }
      this.subjectStatus = this.evalDataId?true:false;
      });
  }

  onChange(event) {
    this.evalnts_status = parseInt(event.target.defaultValue);
  }

  updateEducationMandate() {
    this.gamePlanService.getStudentDetails();
    this.statusMessagesObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        if (res[0].enrollment_date >= "2022-04-01" && res[0].enrollment_date != null && this.gameplanCurrentStatus != null && this.gameplanCurrentStatus != 13) {
          var get_current_order = _.find(this.evalOption, { gameplan_evaluation_confirmation_id: this.gameplanCurrentStatus });
          var get_selected_order = _.find(this.evalOption, { gameplan_evaluation_confirmation_id: this.evalnts_status });
          var previous_status_order = 0;
          for (const iterator of this.evalOption) {
            if(iterator && iterator.previous_gameplan_status != undefined){
              previous_status_order = iterator.order
            }
          }
          if (this.gameplanCurrentStatus == this.evalnts_status && previous_status_order == 0) {
            this.popupService.success('', res.message);
            this.subjectStatus = true;
            this.editStatus = false;
            this.getStudentGameplanStatus();
          }
          else if (get_current_order?.order > get_selected_order?.order || previous_status_order >= get_selected_order?.order) {
            Swal.fire({
              icon: "info",
              title: "You are not allowed to go back to previous status"
            })
          }
          else if (get_current_order?.order < get_selected_order?.order) {
            var swal_message = "";
            this.gamePlanService.getcurrentgpstatus();
            this.gamePlanService.getcurrentgpObserver.subscribe((res: any) => {
              if (res[0].pop_up_count == 1) {
                if (this.gameplanCurrentStatus == 9) {
                  swal_message = "Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 1) {
                  swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 3) {
                  swal_message = "Your are yet to upload your Evaluation Report on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 4) {
                  swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 5) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 7) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 2) {
                  swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 10) {
                  swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 6) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                Swal.fire({
                  icon: "info",
                  html: swal_message,
                  allowOutsideClick: false,
                  confirmButtonColor: '#0069D9',
                  confirmButtonText: "<a style = 'color:#fff' href = '/lms/mileage/gameplan/dashboard'>Ok</a>",
                })
              }
              else if (res[0].pop_up_count == 0 || res[0].pop_up_count == null) {
                if ((this.gameplanCurrentStatus == 2 && this.evalnts_status == 3) || (this.gameplanCurrentStatus == 10 && this.evalnts_status == 5) || (this.gameplanCurrentStatus == 6 && this.evalnts_status == 7)) {
                  const statusvalue = this.evalnts_status;
                  var valArray = [1, 9, 13];
                  this.evaluationModalStatus = true;
                  this.gamePlanService.evalConfirmationPopup({ gameplan_current_status: this.evalnts_status }).subscribe((res: any) => {
                    if (res && res.status) {
                      this.gamePlanService.getMainProgressReport({ type: '' });
                      this.gamePlanService.getDashboardDetails();
                      this.gamePlanService.getNewEvaluation();
                      if (!(valArray.includes(this.evalnts_status))) {
                        this.testStatus = true;
                      }
                      this.popupService.success('', "Your status has been updated successfully!");
                      this.subjectStatus = true;
                      this.editStatus = false;
                      this.getStudentGameplanStatus();

                    } else {
                      this.popupService.error('error', "Error while updating your status");
                      this.subjectStatus = false;
                      this.editStatus = true;
                    }
                    this.ngOnInit();
                  });
                }
                else {
                  if (this.gameplanCurrentStatus == 9) {
                    swal_message = "Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 1) {
                    swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 3) {
                    swal_message = "Your are yet to upload your Evaluation Report on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 4) {
                    swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 5) {
                    swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 2) {
                    swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 10) {
                    swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  else if (this.gameplanCurrentStatus == 6) {
                    swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                  }
                  if (this.gameplanCurrentStatus != 7 && this.gameplanCurrentStatus != 8 && this.gameplanCurrentStatus != 11 && this.gameplanCurrentStatus != 12) {
                    Swal.fire({
                      icon: "info",
                      showCancelButton: true,
                      confirmButtonColor: '#0069D9',
                      cancelButtonColor: '#0069D9',
                      confirmButtonText: 'SKIP FOR NOW',
                      cancelButtonText: "<a style = 'color:#fff' href = '/lms/mileage/gameplan/dashboard'>GAME PLAN TOOL</a>",
                      text: swal_message,
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (res[0].pop_up_count == null) {
                          res[0].pop_up_count = 1
                        }
                        else {
                          res[0].pop_up_count = res[0].pop_up_count + 1
                        }
                        var data = { gameplan_current_status: res[0].gameplan_current_status, pop_up_count: res[0].pop_up_count, old_gp_status: this.evalnts_status }
                        this.gamePlanService.evalConfirmationPopup(data).subscribe((res: any) => {
                          this.popupService.success('', "Your status has been updated successfully!");
                          this.subjectStatus = true;
                          this.editStatus = false;
                          this.getStudentGameplanStatus();
                          this.ngOnInit();
                        });
                      }
                    })
                  }
                  else {
                    const statusvalue = this.evalnts_status;
                    var valArray = [1, 9, 13];
                    this.evaluationModalStatus = true;
                    this.gamePlanService.evalConfirmationPopup({ gameplan_current_status: this.evalnts_status }).subscribe((res: any) => {
                      if (res && res.status) {
                        this.gamePlanService.getMainProgressReport({ type: '' });
                        this.gamePlanService.getDashboardDetails();
                        this.gamePlanService.getNewEvaluation();
                        if (!(valArray.includes(this.evalnts_status))) {
                          this.testStatus = true;
                        }
                        this.popupService.success('', "Your status has been updated successfully!");
                        this.subjectStatus = true;
                        this.editStatus = false;
                        this.getStudentGameplanStatus();

                      } else {
                        this.popupService.error('error', "Error while updating your status");
                        this.subjectStatus = false;
                        this.editStatus = true;
                      }
                      this.ngOnInit();
                    });
                  }
                }
              }
              else if ((this.gameplanCurrentStatus == 9 && this.evalnts_status == 1) || (this.gameplanCurrentStatus == 1 && this.evalnts_status == 2) || (this.gameplanCurrentStatus == 2 && this.evalnts_status == 3) || (this.gameplanCurrentStatus == 10 && this.evalnts_status == 5) || (this.gameplanCurrentStatus == 6 && this.evalnts_status == 7) || (this.gameplanCurrentStatus == 6 && this.evalnts_status == 8) || (this.gameplanCurrentStatus == 7 && this.evalnts_status == 8) || (this.gameplanCurrentStatus == 8 && this.evalnts_status == 11) || (this.gameplanCurrentStatus == 11 && this.evalnts_status == 12)) {
                const statusvalue = this.evalnts_status;
                var valArray = [1, 9, 13];
                this.evaluationModalStatus = true;
                this.gamePlanService.evalConfirmationPopup({ gameplan_current_status: this.evalnts_status }).subscribe((res: any) => {
                  if (res && res.status) {
                    this.gamePlanService.getMainProgressReport({ type: '' });
                    this.gamePlanService.getDashboardDetails();
                    this.gamePlanService.getNewEvaluation();
                    if (!(valArray.includes(this.evalnts_status))) {
                      this.testStatus = true;
                    }
                    this.popupService.success('', "Your status has been updated successfully!");
                    this.subjectStatus = true;
                    this.editStatus = false;
                    this.getStudentGameplanStatus();

                  } else {
                    this.popupService.error('error', "Error while updating your status");
                    this.subjectStatus = false;
                    this.editStatus = true;
                  }
                  this.ngOnInit();
                });
              }
              else {
                if (this.gameplanCurrentStatus == 9) {
                  swal_message = "Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 1) {
                  swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 3) {
                  swal_message = "Your are yet to upload your Evaluation Report on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 4) {
                  swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 5) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 7) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 2) {
                  swal_message = "Your GP SPOC is yet to release your Evaluation Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 10) {
                  swal_message = "Your GP SPOC is yet to release your NTS Advice on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                else if (this.gameplanCurrentStatus == 6) {
                  swal_message = "Your are yet to upload your NTS on the GP Tool. Kindly check if you have uploaded the necessary documents."
                }
                Swal.fire({
                  icon: "info",
                  html: swal_message,
                  allowOutsideClick: false,
                  confirmButtonColor: '#0069D9',
                  confirmButtonText: "<a style = 'color:#fff' href = '/lms/mileage/gameplan/dashboard'>Ok</a>",
                })
              }
            });
          }
        }
        else {
          const statusvalue = this.evalnts_status;
          var valArray = [1, 9, 13];
          this.evaluationModalStatus = true;
          this.gamePlanService.evalConfirmationPopup({ gameplan_current_status: this.evalnts_status }).subscribe((res: any) => {
            if (res && res.status) {
              this.gamePlanService.getMainProgressReport({ type: '' });
              this.gamePlanService.getDashboardDetails();
              this.gamePlanService.getNewEvaluation();
              if (!(valArray.includes(this.evalnts_status))) {
                this.testStatus = true;
              }
              this.popupService.success('', "Your status has been updated successfully!");
              this.subjectStatus = true;
              this.editStatus = false;
              this.getStudentGameplanStatus();

            } else {
              this.popupService.error('error', "Error while updating your status");
              this.subjectStatus = false;
              this.editStatus = true;
            }
            this.ngOnInit();
          });
        }
      }
    });
  }

  // #################### Gameplane Status Block Ends ####################### //

}
