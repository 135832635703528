import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component';
import { MDBBootstrapModulesPro,   
  WavesModule,
  IconsModule,
  ButtonsModule,
  FileInputModule,
  SelectModule,
  InputsModule,
  ModalModule,
  PopoverModule } from 'ng-uikit-pro-standard';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule, 
    MDBBootstrapModulesPro.forRoot(), 
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    WavesModule,
    IconsModule,
    ButtonsModule,
    FileInputModule,
    SelectModule,
    InputsModule,
    ModalModule,
    PopoverModule,
    FormsModule
  ],
  exports: [FeedbackComponent],
  entryComponents: [FeedbackComponent]
})
export class FeedbackModule { }
