import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() { }

  success(tittle, message) {
    Swal.fire({
      title: tittle,
      text: message,
      icon: 'success',
      timerProgressBar: true,
      timer: 2500,
      confirmButtonText: 'Ok',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }

  error(tittle, message, timer?) {
    Swal.fire({
      title: tittle,
      text: message,
      icon: 'error',
      timerProgressBar: true,
      timer: timer || 2500,
      // confirmButtonText: 'Ok',
      // timer: 1500,
              showConfirmButton: false,
              // timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false
    });
  }

  warning(tittle, message) {
    Swal.fire({
      title: tittle,
      text: message,
      icon: 'warning',
      timerProgressBar: true,
      timer: 2500,
      confirmButtonText: 'Ok',
      // timer: 1500,
              showConfirmButton: false,
              // timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false
    });
  }

  successWebinar(tittle, message) {
    Swal.fire({
      title: tittle,
      html: message,
      icon: 'success',
      confirmButtonText: 'Ok'
    });
  }
  appointmentInformation(title, msgIcon, message) {
    Swal.fire({
      title: title,
      html: message,
      icon: msgIcon,
      confirmButtonText: 'Ok'
    });
  }

  naInfo(tittle, message) {
    Swal.fire({
      title: tittle,
      text: 'This feature is restricted only to Miles CPA students.',
      icon: 'warning',
      timerProgressBar: true,
      timer: 2500,
      confirmButtonText: 'Ok'
    });
  }

  newMenuPopup(tittle, message) {
    Swal.fire({
      title: tittle,
      text: 'This Feature will be coming soon!',
      icon: 'info',
      timerProgressBar: true,
      timer: 2500,
      confirmButtonText: 'Ok'
    });
  }

  feedbackPopup(tittle, message) {
    return Swal.fire({
      title: tittle,
      html: message,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Continue',
      showCancelButton: true,
      cancelButtonText: 'Exit'
    })
  }  

}
