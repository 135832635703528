import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import { PopupService } from 'src/app/_clusters/services/popup.service';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';
import Swal from 'sweetalert2';
import * as _ from 'underscore';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-evaluation-step2-modal',
  templateUrl: './evaluation-step2-modal.component.html',
  styleUrls: ['./evaluation-step2-modal.component.scss']
})
export class EvaluationStep2ModalComponent implements OnInit {
  modalRef: MDBModalRef;
  agencyArray = [];
  agencyAdminArray = [];
  stateboardArray: any = [];
  state_board_id: any;
  agency_id: any;
  agency_state_board: any;
  planDetails: any = {};
  stateBoardEvaluationAgencyVal: any;
  transcript_email: string;
  evaluation_email: string;
  gameplanStatus: any;
  step3statusId = [10,5,3,4];
  step3Status: any = false;
  step4statusId = [6,7,8,11,12];
  step4Status: any = false;
  evaluationArray: any = {};
  ntsArray: any = {};
  isAPIWorks = false;
  stateboard: any = '';
  agency: any = '';
  userData: any = {};
  uploadtype: any;
  ntsAdminArray: any = {};
  idArray = [{id:101, name: 'AUD', isChecked: false},{id:102, name: 'BEC', isChecked: false},{id:103, name: 'FAR',isChecked: false},{id:104, name: 'REG', isChecked: false}]
  evaluationAgencySubject = this.gamePlanService.evaluationAgencySubject.asObservable();
  agencyAdminListObserver = this.gamePlanService.agencyAdminListObserver.asObservable();
  getEvaluationObserver = this.gamePlanService.getEvaluationObserver.asObservable();
  metadataObserver = this.gamePlanService.metadataObserver.asObservable();
  getNtsObserver = this.gamePlanService.getNtsObserver.asObservable();
  // ######### //
  @Input() responses: Array<any>;
  progress = 0;
  public uploader: FileUploader;
  uploadingFor;
  showProgress = false;
  showAttached = false;
  otb_id = '';
  viewFileArrayEvaluation = [];
  viewFileArrayNts = [];
  viewFileArrayPassport = [];
  nts_email: any = '';
  nts_reappear_email: any = '';
  // ######### //
  studentId = [];
  ntsStatus: any;
  showNtsBlock: any = false;
  international_testing_email: any = '';
  exam_scheduling_email: any = '';
  ntsSubscription: any;
  getNtsAdminObserver = this.gamePlanService.getNtsAdminObserver.asObservable();
  constructor(private modalService: MDBModalService, private zone: NgZone, private gamePlanService: GamePlanService, private popupService: PopupService, private router: Router,) { }

  ngOnInit(): void {

    // console.log('this.userData',this.userData.otb_id);
    this.gamePlanService.getEvaluationAgency();
    this.evaluationAgencySubject.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.agencyArray = res;
        this.agencyArray.forEach((x) => {
          x.agencyName = x.name +' ('+x.short_code+') ';
        });
        // console.log('this.agencyArray:',this.agencyArray);
      }
    });

    this.gamePlanService.getStateBoard();
    this.gamePlanService.stateBoardSubject.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.stateboardArray = res;
        this.stateboardArray.forEach((x) => {
          x.stateboardName = x.name +' '+x.short_code;
        });
      }
    });
    this.gamePlanService.getAdminAgencyList();
    this.agencyAdminListObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.agencyAdminArray = res;
        // console.log('res :',res);
      }
    });
    this.upload();
    this.step3Status = this.step3statusId.includes(this.gameplanStatus)?true:false;
    this.step4Status = this.step4statusId.includes(this.gameplanStatus)?true:false;
    this.getEvaluationDetails();

   this.getNtsAdminObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        // console.log('getNtsAdminObserver called',res);
        this.ntsAdminArray = res[0];
        // this.uploadNTSexamschedulingData();
       // this.gamePlanService.getNtsAdminObserver.next([]);
      }
    });


  }
  hide() {
    this.modalService.hide(1);
  }

  sendEvaluationAdviceOnly(){
    // const params = {"student_id":this.student_id}let student_id = this.userData.otb_id;
    this.planDetails.call_from = "lms";
    this.planDetails.student_id = this.userData.otb_id;
    this.planDetails.status = 3;
    this.planDetails.message = 'Verified';
    this.planDetails.transcript_email = JSON.stringify({ 'transcript_email': this.transcript_email, 'additional': '' });
    this.planDetails.evaluation_email = JSON.stringify({ 'evaluation_email': this.evaluation_email, 'additional': '' });
    this.planDetails.evaluation_agency = this.agency_state_board.toString();
    this.gamePlanService.updateEducationStatus(this.planDetails).subscribe(async (res: any) => {
      if (res && res.status) {
        if(this.step3Status || this.step4Status) {
          this.updateEvalucation();

          // this.getNtsDetails();
        }

         await delay(5000);
         this.router.navigate(['/mileage/gameplan/evaluation/upload-evaluation-report']);
        if((this.step3Status && !this.showNtsBlock)||(!this.step3Status && !this.step4Status && !this.showNtsBlock)){await delay(5000);this.hide();}
      }
      else{
        this.popupService.error('Error', res.message)
      }
    });


  }

  getAgencyStateboard(value){
    this.stateBoardEvaluationAgencyVal = value.title;
      var str = this.stateBoardEvaluationAgencyVal;
      var temp = str.replace(/\-/,'&').split('&');
      var temp2 = temp[1].split('/');
      this.stateboard = temp2[0];
      this.agency = temp[0];
      if (value.transcript_content == 1) {
        this.transcript_email = `<p class="my-3">Hope you are doing well. </p>
        <p class="mb-3">You must obtain transcripts for your education evaluation.</p>
        <p>Few points to remember:</p>
        <ul>
        <li>A transcript is a photocopy of your mark sheets and degree certificate attested by the Controller of Examinations or Registrar of the University.</li>
        <li>Please note that you will require individual year wise marksheets and degree certificate transcript for Bachelor’s and Masters and Professional courses if any. (Consolidated mark sheets for Bachelor's is NOT acceptable unless the university gives it in writing that they do not issue separate mark sheets).</li>
        <li>The seal should not be opened once stamped and received from the university. </li>
        <li>Transcripts can be submitted electronically (<a href="mailto:etranscriptnies@nasba.org">etranscriptnies@nasba.org</a>) provided it is submitted directly from the institution(s).</li>
        </ul>
        <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
      <br>
      <p>Cheers,</p>`;
      }
      else {
        this.transcript_email = `<p class="my-3">We've got some great news for you - you do <strong>NOT</strong> need to get your university transcripts. Just need notarized copies.</p>
        <p class="mb-3">Please go to a local Notary Public (Advocate) and get photocopies of all your marksheets and degree certificates notarized.
        Once you have the notarized copies, please follow the steps as detailed in Evaluation Instructions.</p>

        <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
        <br>
        <p>Cheers,</p>`;
      }

      this.evaluation_email = `
      <p class="my-3">Hope you are doing well. </p>
      <p class="mb-3">You are advised to proceed with the state board of <strong>${this.stateboard}</strong> for CPA examination. Your suggested evaluation agency is <strong>${this.agency}</strong>.</p>
      <p class="mb-3">Please follow the steps as detailed below to proceed further with your evaluation process.</p>
      <p>Few points to remember: </p>
      <ul>
        <li>The names on the marksheet and/or transcripts must match your passport. In case it does not, then an affidavit must be sent along with the notarised documents.</li>
        <li>Copy of passport (first page and last page) must be sent with all documents. In the absence of a passport, any other government authorized ID such as an Aadhar Card copy can also be sent. However, the original passport is a required proof of identity to appear for the exams.</li>
        <li>The normal process time for education evaluation is generally 4-8 weeks. Once completed, one copy of your evaluation report is mailed to the state board and the other copy will be emailed to you.</li>
        <li>After submitting the online form, you will receive an application ID. The application ID must be mentioned  on the envelope containing the documents that is to be couriered to the evaluation agency.</li>
        <li>Notice to Schedule (NTS) is the next step after issuance of your evaluation report. The instructions and payment details will be shared after the evaluation process is completed. </li>
      </ul>
      <p class="mb-2">Once your evaluation is completed, please upload a copy of the evaluation report on the "<strong>Upload Evaluation Report</strong>" tab on the LMS.</p>
      <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
      <br>
      <p>Cheers,</p>`;

      this.loadEmailData(this.stateboard);
  }

  // ###################### Uploading of Documents Begins ###################### //
  upload() {
    // Create the file uploader, wire it to upload to your account
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/duthqxswg/upload`,
      // Upload files automatically upon addition to upload queue
      allowedMimeType: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'],
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      maxFileSize: 5 * 1021 * 1021,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Add Cloudinary unsigned upload preset to the upload form

      form.append('upload_preset', 'itx0z6fi');
      form.append('folder', this.otb_id + '/' + 'evaluation');
      // Add file to upload
      form.append('file', fileItem);

      // Use default "withCredentials" value for CORS requests
      fileItem.withCredentials = false;
      return { fileItem, form };
    };
    const upsertResponse = fileItem => {

      // Run the update in a custom zone since for some reason change detection isn't performed
      // as part of the XHR request to upload the files.
      // Running in a custom zone forces change detection
      this.zone.run(() => {
        // Update an existing entry if it's upload hasn't completed yet

        // Find the id of an existing item
        //// console.log('responses', this.responses);
        const existingId = this.responses.reduce((prev, current, index) => {
          if (current.file.name === fileItem.file.name && !current.status) {
            return index;
          }
          return prev;
        }, -1);
        if (existingId > -1) {
          // Update existing item with new data
          this.responses[existingId] = Object.assign(this.responses[existingId], fileItem);
        } else {
          // Create new response
          this.responses.push(fileItem);
        }
      });
    };

    // Update model on completion of uploading a file
    this.uploader.onCompleteAll = () => {
      //// console.log('complete');
      this.showProgress = false;
      this.showAttached = true;
    };
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) => {
      const uploadRes = JSON.parse(response);

      if(this.uploadtype == 1){
        this.viewFileArrayEvaluation.push({
          name: 'Evaluation_Doc',
          path: uploadRes.secure_url,
          format: uploadRes.format,
          size: uploadRes.bytes,
          status: 0
        });
      }
      if(this.uploadtype == 2){
        this.viewFileArrayNts.push({
          name: 'NTS_Doc',
          path: uploadRes.secure_url,
          format: uploadRes.format,
          size: uploadRes.bytes,
          status: 0
        });
      }
      if(this.uploadtype == 3){
        this.viewFileArrayPassport.push({
          name: 'passportcopy',
          path: uploadRes.secure_url,
          format: uploadRes.format,
          size: uploadRes.bytes,
          status: 0
        });
      }


      upsertResponse(
        {
          file: item.file,
          status,
          data: uploadRes
        }
      );
    };
    this.uploader.onErrorItem = (fileItem: any, response: string, status: number, headers: ParsedResponseHeaders) => {
      // const uploadRes = JSON.parse(response);
      // //// console.log(response);
      // //// console.log(status);
    }
    // Update model on upload progress event
    this.uploader.onProgressItem = (fileItem: any, progress: any) => {
      // //// console.log('started');
      this.showProgress = true;
      upsertResponse(
        {
          file: fileItem.file,
          progress,
          data: {}
        }
      );
    };
    this.uploader.onCancelItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) => {
      // //// console.log(response);
      // //// console.log(status);
    }
    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      this.showProgress = false;
      this.showAttached = false;
      let errorMsg = '';
      let footerMsg = '';
      if (item.size > (5 * 1021 * 1021)) {
        errorMsg = 'File size must be less than 5mb'
      } else {
        errorMsg = 'Invalid File Type';
        footerMsg = 'Supported File Type : jpeg, jpg, png, docx, pdf, txt';
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMsg,
        footer: footerMsg
      });
    };
  }

  currentUploading(type,event) {
    this.responses = [];
    this.uploadtype = type;
    // this.uploadingFor=device;
    let target = event.target || event.srcElement;
    target.value = '';
  }

  removeAttachment(i) {
    this.viewFileArrayEvaluation.splice(i, 1);
  }
  // ####################### Uploading of Documents Ends ####################### //
  // ####################### Evaluation Student and Admin Functions Begins ####################### //
  getEvaluationDetails(){
    this.gamePlanService.getNewEvaluation();
    this.getEvaluationObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.isAPIWorks = true;
        this.evaluationArray = res[0];
        this.agency_id = res[0].evaluation_agency_id;
        this.viewFileArrayEvaluation = res[0]?.uploaded_file_name ? res[0]?.uploaded_file_name : [];
        this.state_board_id = res[0].state_board_id;
        // //// console.log('agencyid', res);
      }
    });

  }

  updateEvalucation() {
    this.getNtsDetails();
    this.getAdminEval();
  }

  getAdminEval(){
    let student_id = this.userData.otb_id;
    let call_from : 'lms';
    const param3 = {
      call_from : 'lms',
      student_id: student_id,
      status: 3,
      uploaded_file_name: JSON.stringify(this.viewFileArrayEvaluation),
      message: '',
    };
    this.gamePlanService.updateAdminEvaluation(param3).subscribe(async (res: any) => {
      if (res && res.status) {
        this.popupService.success('Success', res.message);
        // await delay(5000);
        this.gamePlanService.getAdminEvaluation(student_id,call_from);
        this.gamePlanService.getAdminNts(student_id,call_from);
        await delay(5000);
        this.router.navigate(['/mileage/gameplan/evaluation/upload-evaluation-report']);
        // if(this.step4Status){this.showNtsBlock = true;}
        // else{this.showNtsBlock = false;this.hide();}
         this.showNtsBlock = this.step4Status?true:false;
        //this.showNtsBlock = true;
        if(!this.showNtsBlock){ this.updateEmail();}


      } else {
        this.popupService.error('Error', res.message);
      }
    });
  }

  loadEmailData(stateboardValue: any) {
    this.nts_email = `

    <p class="my-3">Hope you are doing well. </p>
    <p class="mb-3">Since you have received your evaluation report, you are now advised to proceed  further with the state board of <strong>${stateboardValue}</strong> for Notice to Schedule (NTS).</p>

    <p class="mb-3">Please follow the steps as detailed below to proceed with NTS process</p>

    <p>Few points to remember: </p>
    <ul>
      <li>An application form needs to be filled to apply for the NTS.</li>
      <li>Payment can be done via credit card for the application process.</li>
      <li>NTS can be applied for one or more subjects at a time. It is not necessary that NTS for all 4 CPA papers must be applied for at once.</li>
      <li>At this stage, for non-US education, you are not required to send the transcripts as your evaluation report is sent to the state board.</li>
      <li>Acceptance of the exam application takes about 4-6 weeks by the state board, post which you will be emailed the NTS.</li>
      <li>Each NTS is valid for a period of 6-12 months, depending on the state board.</li>
      <li>Each NTS is valid for only one sitting of the CPA exam. In case you do not pass, you have to reapply for the NTS.</li>
    </ul>
    <p class="mb-3">Once you have received your NTS, please upload a copy of the same on the "<strong>Upload NTS</strong>" tab on the LMS. </p>
    <p class="mb-3">Thereafter, you will receive instructions for international testing (if applicable) and exam scheduling on Prometric.</p>
    <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
    <br>
    <p>Cheers</p>`;

    this.nts_reappear_email = `
    <p class="my-3">Hope you are doing well. </p>
    <p class="mb-3">Reapplication of Notice To Schedule (NTS) is applicable for those who have to re attempt  a section or apply  NTS for un attempted subjects with the same state board.</p>
    <p class="mb-3">The detailed instructions for re-application are given below.</p>
    <p>Few points to remember: </p>
    <ul>
      <li>An application form needs to be filled to reapply for the NTS.</li>
      <li>Payment can be done via credit card for the re-application.</li>
      <li>Acceptance of the re-exam application takes about a week’s time by the state board. You will receive your NTS by email.</li>
      <li>Each NTS is valid for a period of 6-12 months, depending on the state board.</li>
    </ul>
    <p class="mb-3">Once you have received the NTS, please upload a copy of the same on the "<strong>Upload NTS</strong>" tab on the LMS.</p>
    <p class="mb-3">Thereafter, you will receive instructions for international testing (if applicable) and exam scheduling on Prometric. </p>
    <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
    <br>
    <p>Cheers</p>`;
  }

  // ######################## Evaluation Student and Admin Functions Ends ######################## //

    updateEmail() {
      // this.loadEmailData(this.stateboard);
    //this.userData.otb_id;
    // console.log(this.stateboard);

    let student_id = this.userData.otb_id;
    const params = {
      call_from : "lms",
      id: 0,
      student_id: student_id,
      email: JSON.stringify({ 'email': this.nts_email, 'additional': '' }),
      nts_reappear_email: JSON.stringify({ 'nts_reappear_email': this.nts_reappear_email, 'additional': '' })
    };
    this.gamePlanService.updateAdminNts(params).subscribe(async (res: any) => {
      if (res && res.status) {
        this.gamePlanService.getAdminNts(student_id,"lms");

          await delay(5000);
          this.showNtsBlock = true;
      } else {
        this.popupService.error('Error', res.message);
      }
    });

  }


  // ######################## NTS Student and Admin Functions Begins ######################## //
  getNtsDetails(){
    this.gamePlanService.getNewNts();
      this.getNtsObserver.subscribe((res: any) => {
        if (!_.isEmpty(res)) {
          this.isAPIWorks = true;
          this.ntsArray = res[0];
          this.viewFileArrayNts = res[0]?.uploaded_file_name ? res[0]?.uploaded_file_name : [];
          this.viewFileArrayPassport = res[0]?.passport_file ? res[0]?.passport_file : [];
          this.ntsStatus = this.ntsArray.nts_status;
        }

      });
  }
  onNtsChange(event, index) {
    if (event.checked) {
      this.studentId.push(event.element.value);
    } else {
      this.studentId.splice(index, 1);
    }
  }
  updateNTSDocuments(){
    const params = {
      id: this.ntsArray.gameplan_nts_id,
      uploaded_file_name: JSON.stringify(this.viewFileArrayNts),
      passport_file: JSON.stringify(this.viewFileArrayPassport),
      subject_id: this.studentId.toString(),
      nts_status: 3,
      email: JSON.stringify({ 'email': this.nts_email, 'additional': '' }),
      nts_reappear_email: JSON.stringify({ 'nts_reappear_email': this.nts_reappear_email, 'additional': '' })
    };
    const ntsparams2 = { message: 'success'};
    this.gamePlanService.updateNewNts(params).subscribe(async (res: any) => {
      if (res && res.status) {
        this.gamePlanService.previewAndUpdateNts(ntsparams2).subscribe(async (res: any) => {
        });

        this.gamePlanService.getNewNts();
        this.updateExamEmails();
        await delay(5000);
        this.router.navigate(['/mileage/gameplan/nts/upload-nts']);

        this.gamePlanService.getAdminNts(this.userData.otb_id,'lms');

        this.hide();
      } else {
        this.popupService.error('Error', res.message);
      }
    });
  }


  // ######################### NTS Student and Admin Functions Ends ######################### //
  loadExamschedulingLetter(){
    this.international_testing_email = `<ul class="mt-3">
    <li><strong>If you are appearing for the CPA Exams in the US, then you can skip this step and click on Prometric Exam Scheduling Instructions.</strong></li>
    <li>If you are appearing for your CPA exams outside of the US like in India or Dubai etc , you are required to pay an international testing fee before you select the exam dates on the Prometric website.</li>
    </ul>

    <p><strong>Few important points to remember: </strong></p>
  <ul>
    <li>International testing fees are applicable for each part of the US CPA exam.</li>
    <li>Exam slots on the prometric website can be booked after 24 hours of paying the international testing fees.</li>
    <li>Ensure that the name that you use on the account matches the name on the passport.</li>
    <li>Payment of International testing fee can be done via Credit Card Only.</li>
  </ul>
  <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
  <br>
  <p>Cheers</p>`;

    this.exam_scheduling_email = `<p class="my-3">If you are appearing for CPA exams in the US, you are not required to pay the international testing fees and can directly book your exam slots on the Prometric website.</p>
    <p class="mb-3">If you are appearing for the CPA exam outside the US, for example in India or Dubai, you must pay the international testing fees before booking the exam slots. Once the international testing payment is done, you must wait for 24 hours before booking the examination slots on the Prometric website.</p>
    <p class="mb-3">Please follow the instructions  given below for booking the examination slots.</p><p class="mb-2"><strong> Few points to remember:</strong></p>
<ul>
  <li>You can check the slots available using the seat availability tool at any time prior to booking on the Prometric website.</li>
  <li>You can cancel/reschedule your exam on the prometric website.</li>
  <li>Ensure that your name matches your primary identification (example - Passport)</li>
  <li>Make sure you carry your original valid passport & NTS with you on the day of the exam to the exam center</li>
  <li>Always plan to visit the exam center prior to the exam date.</li>
  <li>Refer to the <a href="https://drive.google.com/file/d/11wvQ8F7YFdWji5B02RrM5TBsxSM20-bV/view?usp=drivesdk" target="_blank">Last Minute Checklist</a> before you visit the prometric center.</li>
</ul>
<p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
<br>
  <p>Cheers</p>`;

  }


  uploadNTSexamschedulingData(){
    const params = {
      id: this.ntsAdminArray.gameplan_nts_id,
      uploaded_file_name: JSON.stringify(this.ntsAdminArray.uploaded_file_name),
      nts_status: 3,
      message: '',
      call_from : 'lms',
      student_id : this.userData.otb_id
    };

    this.gamePlanService.updateAdminNts(params).subscribe(async (res: any) => {
      if (res && res.status) {
        this.popupService.success('Success', res.message);
        await delay(5000);
        this.updateExamEmails();
      } else {
        this.popupService.error('Error', res.message);
      }
    });

  }
  updateExamEmails() {
    this.loadExamschedulingLetter();
    let student_id = this.userData.otb_id;

    const params = {
      call_from : 'lms',
      student_id: student_id,
      international_testing_email: JSON.stringify({ 'international_testing_email': this.international_testing_email, 'additional': ''}),
      exam_scheduling_email: JSON.stringify({ 'exam_scheduling_email': this.exam_scheduling_email, 'additional': '' })
    }
    this.gamePlanService.updateAdminExamEmail(params).subscribe((res: any) => {
      if (res && res.status) {
        this.popupService.success('Success', res.message);
        this.gamePlanService.getAdminExamData(student_id, 'lms');
      } else {
        this.popupService.error('Error', res.message);
      }
    });
  }


}
