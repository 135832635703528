import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameplanModalComponent } from './gameplan-modal.component'
import { from } from 'rxjs';
import { RouterModule } from '@angular/router';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { EvaStatusPopupModule } from '../../_my-space/gameplantool/common-modal/eva-status-popup/eva-status-popup.module';
// import { EvaConfirmationComponent } from '../../_my-space/gameplantool/common-modal/eva-confirmation/eva-confirmation.component';
import { EvaConfirmationModule } from '../../_my-space/gameplantool/common-modal/eva-confirmation/eva-confirmation.module';


@NgModule({
  declarations: [GameplanModalComponent],
  imports: [
    CommonModule, MDBBootstrapModulesPro.forRoot(), RouterModule, NgSelectModule, FormsModule, EvaConfirmationModule
  ],
  exports: [GameplanModalComponent],
  entryComponents: [GameplanModalComponent]
})
export class GameplanModalModule { }
