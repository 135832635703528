import { MDBModalRef } from 'ng-uikit-pro-standard';
import { EmailService } from 'src/app/_clusters/services/email.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {
  contactDetailsObserver = this.contactModal.contactDetailsObserver.asObservable();
  sr_manager: any =[];
  auditing: any = [];
  academic: any = [];
  business: any = [];
  financial: any = [];
  regulation:any = [];
  books_Access: any = [];
  support: any = [];
  operations: any = [];
  gameplan_manager: any = [];
  isMobile: boolean;
  isTablet: boolean;
  constructor(private contactModal: EmailService, private modalRef: MDBModalRef, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.contactModal.conatctDetailsList();

    this.contactDetailsObserver.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        console.log('conactDetails', res);
        this.sr_manager = res.sr_manager;
        this.gameplan_manager = res.gameplan_manager;
        this.auditing = res.auditing;
        this.business = res.business;
        this.financial = res.financial;
        this.regulation= res.regulation;
        this.books_Access = res.books_Access;
        this.support = res.support;
        this.operations = res.operations;
        this.academic = res.academic;
      }
    });
  }

  hide() {
    //// console.log('yes');
    this.modalRef.hide();
  }

}
