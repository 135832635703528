<div class="modal-header py-1">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();" style="margin-top: -12px;">
      <span aria-hidden="true">×</span>
    </button>
    <span class="modal-title w-100" id="myModalLabel" style="font-weight: normal;font-size: 1.3rem !important;">CPA Evolution</span>
  </div>
  <div class="modal-body">
    <div class="reference_table2 table-responsive">
      <table class="additional_content_Update table table-bordered">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-file-signature mr-2" style="font-size: 1.3rem;"></i>Key Updates</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">1.CPA exam is changing</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/1_CPA_exam_is_changing.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">2.CPA Evolution what why when</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/2_CPAEvolution_what_why_when.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">3.CPA Evolution transition policy</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/3_CPAEvolution_transitionpolicy.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">4.CPA Evolution Important tentative dates</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/4_CpaEvolution_Important tentative_dates.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">5.CPA Evolution creditextension</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/5_CPAEvolution_creditextension.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">CPA Evolution FAQs.pdf</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/CPAEvolution_FAQs.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
              <span class="blinking">New</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
