import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-calulator',
  templateUrl: './calulator.component.html',
  styleUrls: ['./calulator.component.scss']
})
export class CalulatorComponent implements OnInit {

  showCal = true;
  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

}
