<div class="modal-header py-1">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();" style="margin-top: -12px;">
      <span aria-hidden="true">×</span>
    </button>
    <span class="modal-title w-100" id="myModalLabel" style="font-weight: normal;font-size: 1.3rem !important;">Additional Resources</span>
  </div>
  <div class="modal-body">
    <div class="reference_table2 table-responsive">
      <table class="additional_content_Update table table-bordered d-none">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-file-signature mr-2" style="font-size: 1.3rem;"></i>Key Updates</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">CPA - July 2021</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Miles_CPA_July_2021_Updates.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download </a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ############### AUD Additional Content Updates Begins ############### -->
      <table class="additional_content_Update table table-bordered">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-search-dollar mr-2" style="font-size: 1.3rem;"></i>Auditing - AUD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AICPA_AUD_updated.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>&nbsp;
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AICPA_AUD_Final_22.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2022</a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions - Answer Explanations</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Auditing_AICPA_Released_Qs_2021_Answer_Explanations.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ################ AUD Additional Content Updates Ends ################ -->
      <!-- ############### BEC Additional Content Updates Begins ############### -->
      <table class="additional_content_Update table table-bordered">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-chart-line mr-2" style="font-size: 1.3rem;"></i>Business - BEC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AICPA_BEC.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>&nbsp;
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AICPA_BEC_Final_22.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2022</a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions - Answer Explanations</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_AICPA_Released_Qs_2021_Answer_Explanations.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ################ BEC Additional Content Updates Ends ################ -->
      <!-- ############### FAR Additional Content Updates Begins ############### -->
      <table class="additional_content_Update table table-bordered">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-university mr-2" style="font-size: 1.3rem;"></i>Financial - FAR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AICPA_FAR.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>&nbsp;
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AICPA_FAR_Final_22.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2022</a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions - Answer Explanations</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_AICPA_Released_Qs_2021_Answer_Explanations.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ################ FAR Additional Content Updates Ends ################ -->
      <!-- ############### REG Additional Content Updates Begins ############### -->
      <table class="additional_content_Update table table-bordered mb-0">
        <thead>
          <tr>
            <th colspan="2"><i class="fad fa-gavel mr-2" style="font-size: 1.3rem;"></i>Regulation - REG</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions</td>
            <td class="text-center">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AICPA_REG.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>&nbsp;
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AICPA_REG_Final_22.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2022</a>
              <span class="blinking">New</span>
            </td>
          </tr>
          <tr>
            <td class="text-center" style="width:40%">AICPA Released Questions - Answer Explanations</td>
            <td class="text-center">
              <a href="https://streaming.mileseducation.com/cpa_ebooks/Regulation_AICPA_Released_Qs_2021_Answer_Explanations.pdf" target="_blank" class="btn btn-sm btn-indigo m-0" style="font-size: 13px; border-radius: 4px; white-space: nowrap;"><i class="fas fa-download mr-2" style="font-size: 15px;"></i> Download - 2021</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ################ REG Additional Content Updates Ends ################ -->
    </div>
  </div>
