<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()" style="top: 20px;">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Contact Us</h4>
  </div>
  <div class="modal-body">

    <div class="row support">
      <div class="d-none mx-lg-auto mx-xl-0">
        <img src="../assets/img/support.png" style="width: 250px; max-width: 100%;">
      </div>
      <div class="col-lg-3 col-md-6 col-12 SR-block pt-lg-0 pt-3 pb-3" *ngFor="let item of sr_manager"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100 title-top">
          <p class="font-weight-bold">{{sr_manager[0]?.category_name}}</p>
        </div>
        <div class="contact">
          <i class="fas fa-user support_icon"></i>
          <p>{{item?.spoc_name}}</p>
          <p *ngIf="!item?.spoc_name" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
        <div class="contact">
          <i class="fas fa-phone-alt support_icon" style="transform: rotate(90deg);"></i>
          <p *ngIf="item?.spoc_mobile"><a href="{{'tel:' + item?.spoc_mobile}}">{{item?.spoc_mobile}}</a> <a
              href="https://wa.me/{{item?.spoc_mobile.replace('+91 ', '')}}?text=Hello" target="_blank"
              style="background: #00a82d; color: #fff; padding: 2px 5px; border-radius: 5px; margin-left: 5px;"
              mdbTooltip="Connect on whatsapp" placement="top"><i class="fab fa-whatsapp"></i></a></p>
          <p *ngIf="!item?.spoc_mobile" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
        <div class="contact">
          <i class="fas fa-envelope support_icon"></i>
          <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
              href="{{'mailto:' + item?.spoc_email}}" title="{{item?.spoc_email}}">{{item?.spoc_email}}</a></p>
          <p *ngIf="!item?.spoc_email" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 SPOC-block pt-lg-0 pt-3 pb-3" *ngFor="let item1 of gameplan_manager"
        [ngClass]="isMobile || isTablet?' custom-contact-border':''">
        <div class="w-100 title-top">
          <p class="font-weight-bold">{{gameplan_manager[0]?.category_name}}</p>
        </div>
        <div class="contact">
          <i class="fas fa-user support_icon"></i>
          <p>{{item1?.spoc_name}} <span>(CPA)</span></p>
          <p *ngIf="!item1?.spoc_name" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
        <div class="contact">
          <i class="fas fa-phone-alt support_icon" style="transform: rotate(90deg);"></i>
          <p *ngIf="item1?.spoc_mobile"><a href="{{'tel:' + item1?.spoc_mobile}}">{{item1?.spoc_mobile}}</a> <a
              href="https://wa.me/{{item1?.spoc_mobile.replace('+91 ', '')}}?text=Hello" target="_blank"
              style="background: #00a82d; color: #fff; padding: 2px 5px; border-radius: 5px; margin-left: 5px;"
              mdbTooltip="Connect on whatsapp" placement="top"><i class="fab fa-whatsapp"></i></a></p>
          <p *ngIf="!item1?.spoc_mobile" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
        <div class="contact">
          <i class="fas fa-envelope support_icon"></i>
          <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
              href="{{'mailto:' + item1?.spoc_email}}" title="{{item1?.spoc_email}}">{{item1?.spoc_email}}</a></p>
          <p *ngIf="!item1?.spoc_email" class="skeleton w-100" style="min-height:15px;"></p>
        </div>
      </div>
    </div>



    <div class="row support">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold"><i class="fad fa-search-dollar mr-2"></i>{{auditing[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let aud of auditing">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{aud.spoc_name}} <span>(CPA)</span></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{aud.spoc_email}}" title={{aud.spoc_email}}>{{aud.spoc_email}}</a></p>
          </div>
          <hr>
        </ng-container>

        <!-- <div class="contact">
          <i class="fas fa-user support_icon"></i>
          <p> Ganesh More <span>(CPA)</span></p>
        </div>
        <div class="contact">
          <i class="fas fa-envelope support_icon"></i>
          <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a href="mailto:ganesh.more@mileseducation.com" title="ganesh.more@mileseducation.com">ganesh.more@mileseducation.com</a></p>
        </div> -->
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold"><i class="fad fa-search-dollar mr-2"></i>{{business[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let bus of business">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{bus.spoc_name}} <span>(CPA)</span></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{bus.spoc_email}}" itle={{bus.spoc_email}}>{{bus.spoc_email}}</a></p>
          </div>

          <hr>
        </ng-container>

        <!-- <div class="contact">
        <i class="fas fa-user support_icon"></i>
        <p> Ganesh More <span>(CPA)</span></p>
      </div>
      <div class="contact">
        <i class="fas fa-envelope support_icon"></i>
        <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a href="mailto:ganesh.more@mileseducation.com" title="ganesh.more@mileseducation.com">ganesh.more@mileseducation.com</a></p>
      </div> -->
      </div>
      <!-- <div class="w-100">
        <hr>
      </div> -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold"><i class="fad fa-university mr-2"></i>{{financial[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let fin of financial">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{fin.spoc_name}}<span>(CPA)</span></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{fin.spoc_email}}" title={{fin.spoc_email}}>{{fin.spoc_email}}</a></p>
          </div>

          <hr>
        </ng-container>

        <!-- <div class="contact">
          <i class="fas fa-user support_icon"></i>
          <p>Kiran Agarwal <span>(CPA)</span></p>
        </div>
        <div class="contact">
          <i class="fas fa-envelope support_icon"></i>
          <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
              href="mailto:kiran.agarwal@mileseducation.com"
              title="kiran.agarwal@mileseducation.com">kiran.agarwal@mileseducation.com</a></p>
        </div> -->
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold"><i class="fad fa-gavel mr-2"></i>{{regulation[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let reg of regulation">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{reg.spoc_name}}<span>(CPA)</span></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{reg.spoc_email}}" title={{reg.spoc_email}}>{{reg.spoc_email}}</a></p>
          </div>
          <hr>
        </ng-container>
        <!-- <hr>
        <div class="contact">
          <i class="fas fa-user support_icon"></i>
          <p>Sejal Jhaveri <span>(CPA)</span></p>
        </div>
        <div class="contact">
          <i class="fas fa-envelope support_icon"></i>
          <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
              href="mailto:sejal.jhaveri@mileseducation.com"
              title="sejal.jhaveri@mileseducation.com">sejal.jhaveri@mileseducation.com</a></p>
        </div> -->
      </div>

    </div>

    <div class="row support" [ngClass]="isMobile || isTablet?'px-0 pb-0 z-depth-0':''">

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold">{{books_Access[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let ba of books_Access">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{ba.spoc_name}}</p>
          </div>
          <div class="contact">
            <i class="fas fa-phone-alt support_icon" style="transform: rotate(90deg);"></i>
            <p><a href="tel:{{ba.spoc_mobile}}">{{ba.spoc_mobile}}</a> <a
                href="https://wa.me/{{ba.spoc_mobile}}?text=Hello" target="_blank"
                style="background: #00a82d; color: #fff; padding: 2px 5px; border-radius: 5px; margin-left: 5px;"
                mdbTooltip="Connect on whatsapp" placement="top"><i class="fab fa-whatsapp"></i></a></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{ba.spoc_email}}" title="{{ba.spoc_email}}">{{ba.spoc_email}}</a></p>
          </div>
        </ng-container>

      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold">{{support[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let sup of support">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{sup.spoc_name}}</p>
          </div>
          <div class="contact">
            <i class="fas fa-phone-alt support_icon" style="transform: rotate(90deg);"></i>
            <p><a href="tel:{{sup.spoc_mobile}}">{{sup.spoc_mobile}}</a> <a
                href="https://wa.me/{{sup.spoc_mobile}}?text=Hello" target="_blank"
                style="background: #00a82d; color: #fff; padding: 2px 5px; border-radius: 5px; margin-left: 5px;"
                mdbTooltip="Connect on whatsapp" placement="top"><i class="fab fa-whatsapp"></i></a></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{sup.spoc_email}}" title="{{sup.spoc_email}}">{{sup.spoc_email}}</a>
            </p>
          </div>
        </ng-container>

      </div>


      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold">{{operations[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let op of operations">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{op.spoc_name}}</p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{op.spoc_email}}" title="{{op.spoc_email}}">{{op.spoc_email}}</a></p>
          </div>
        </ng-container>

      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-lg-0 pt-3 pb-3"
        [ngClass]="isMobile || isTablet?'custom-contact-border':''">
        <div class="w-100">
          <p class="font-weight-bold">{{academic[0]?.category_name}}</p>
        </div>
        <ng-container *ngFor="let ac of academic">

          <div class="contact">
            <i class="fas fa-user support_icon"></i>
            <p>{{ac.spoc_name}} <span>(CPA)</span></p>
          </div>
          <div class="contact">
            <i class="fas fa-envelope support_icon"></i>
            <p style="word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a
                href="mailto:{{ac.spoc_email}}" title="{{ac.spoc_email}}">{{ac.spoc_email}}</a></p>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>