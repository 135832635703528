<div class="modal-content">
  <div class="modal-header py-2" style="background: #4285f4;color: #fff;">
    <h4 class="modal-title w-100 text-center font-weight-bold" id="myModalLabel">Exam Gameplan</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid pb-2">
      <p><strong>Hi&nbsp;{{studentDetails.stud_name}}</strong>,<br>We want to know your tentative plan for the CPA exams
        so we can set up your
        LMS. </p>
      <div class="row">
        <div class="col-12 mx-auto" [ngClass]="isMobile?'px-0':''">
          <p class="mt-4"><b>Your Current Gameplan Status : </b></p>
          <div class=" d-flex align-content-around align-items-center">
            <!-- <label class="control-label"><b>Your Current Gameplan Status : </b></label> -->
            <label *ngIf="evalDataStatus" class="control-label ml-2 my-auto form-control">{{evalDataStatus}}</label>
            <!-- <input type="text" disabled class="form-contol w-100 text-center" [(ngModel)]="evalDataStatus"> -->
            
            <!-- <ng-select class="w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="evalOption" name="evalData"
                    bindLabel="option" [(ngModel)]="evalDataId" bindValue="gameplan_evaluation_confirmation_id"
                    placeholder="Choose option" required [readonly]="!editStatus" disabled>
                  </ng-select> -->
                 <ng-container *ngIf="evalDataStatus">

                  <button *ngIf="!editStatus && evalDataVal" mdbBtn type="button" color="primary" size="sm" class="my-auto" (click)="editStatus = true;subjectStatus = false">Edit</button>

                  <button *ngIf="editStatus && evalDataVal"  mdbBtn type="button" color="danger" size="sm" class="my-auto" (click)="editStatus = false;subjectStatus = true">Back</button>
                </ng-container>
                </div>  

          <div *ngIf="editStatus || !!!evalDataStatus">
            <!-- <app-eva-confirmation></app-eva-confirmation> -->
            <div class="row mx-0" *ngIf="evalOption.length > 0">
              <div class="col-12 px-0">
                <div *ngFor="let eval of evalOption">
                  <ng-container *ngIf="eval.gameplan_evaluation_confirmation_id !=13">
                  <div *ngIf="evalData?.studyplanner_status != null" class="form-check py-1 px-0">
                    <input *ngIf="eval.option == evalData?.studyplanner_status" type="radio" class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                      name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" checked (change)="onChange($event)" mdbInput>
                    <input *ngIf="eval.option != evalData?.studyplanner_status" type="radio" class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                      name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" (change)="onChange($event)" mdbInput>
                    <label class="form-check-label"
                      for="evalOption_{{eval.gameplan_evaluation_confirmation_id}}" style="font-size:14px">{{eval.option}}</label>
                  </div>
                  <div *ngIf="evalData?.studyplanner_status == null" class="form-check py-1 px-0">
                    <input *ngIf="eval.option == evalData?.gameplan_current_status" type="radio" class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                      name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" checked (change)="onChange($event)" mdbInput>
                    <input *ngIf="eval.option != evalData?.gameplan_current_status" type="radio" class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                      name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" (change)="onChange($event)" mdbInput>
                    <label class="form-check-label"
                      for="evalOption_{{eval.gameplan_evaluation_confirmation_id}}" style="font-size:14px">{{eval.option}}</label>
                  </div>
                </ng-container>
                </div>
                <!-- Do it on monday *ngIf="eval.option == evalData?.gameplan_current_status && eval.previous_gameplan_status == undefined" -->
              </div>
              <button type="button" mdbBtn color="primary" [disabled]="evalnts_status == 0"
                class="relative waves-light btn btn-primary btn-md" mdbWavesEffect
                (click)="updateEducationMandate()">Proceed</button>
                
            </div>
          </div>


        </div>

      </div>
      <ng-container *ngIf="subjectStatus">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
          <div class="mt-4 d-flex align-content-around align-items-center">
            <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-aud">
              <form name="audForm" #audForm="ngForm" class="mx-auto w-100">
                <div style="margin: 5px;text-align: center;">Auditing &amp; Attestation</div>
                <div style="margin: 5px;" class="md-form">
                  <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="statusList" name="audstatusList"
                    bindLabel="exam_status_type" [(ngModel)]="audData.exam_status" bindValue="exam_status_id"
                    placeholder="Choose option" (change)="audChange()" required>
                  </ng-select>
                </div>

                <div class="d-flex flex-fill">
                  <div class="col mx-1 px-0">
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="audData.exam_status===1 || audData.exam_status===2 || audData.exam_status===5">
                      <input type="text" id="plannercalender" class="bg-white form-control" name="date"
                        [(ngModel)]="audData.date_of_exam" (focus)="monthModal.show();switchId=1" required mdbInput
                        mdbValidate>
                      <label for="plannercalender">Planned Month</label>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="audData.exam_status===3 || audData.exam_status===6">
                      <mdb-date-picker [outlineInput]="true" id="form2" name="mydate" [options]="myDatePickerOptions"
                        class="custom-date-picker-input" [ngClass]="audData.exam_status===4 ? 'custom-width':''"
                        mdbValidate mdbInput [(ngModel)]="audData.date_of_exam" [placeholder]="'Scheduled date'" required>
                      </mdb-date-picker>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="audData.exam_status===4 || audData.exam_status===7">
                      <mdb-date-picker [outlineInput]="true" id="passform2" name="mypassdate"
                        [options]="passedDateOption" class="custom-date-picker-input"
                        [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbValidate mdbInput
                        [(ngModel)]="audData.date_of_exam" [placeholder]="'Exam date'" required>
                      </mdb-date-picker>
                    </div>
                  </div>
                  <div class="col mx-1 px-0" *ngIf="audData.exam_status===4">
                    <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                      [ngClass]="audData.exam_status===4 ? 'custom-width-score':''">
                      <!-- <input type="text" id="audscore" class="bg-white form-control" name="audscore" mdbValidate
                        mdbInput [(ngModel)]="audData.score" required>
                      <label for="audscore">Your Score</label> -->
                      <ng-select [items]="scoreArray" name="audscore" [(ngModel)]="audData.score"
                        placeholder="Choose score" required>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
          <div class="mt-4 d-flex align-content-around align-items-center">
            <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-bec">
              <form name="becForm" #becForm="ngForm" class="mx-auto w-100">
                <div style="margin: 5px;text-align: center;">Business Environment &amp; Concepts</div>
                <div style="margin: 5px;" class="md-form">
                  <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="statusList" name="becstatusList" bindLabel="exam_status_type"
                    [(ngModel)]="becData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                    (change)="becChange()">
                  </ng-select>
                </div>

                <div class="d-flex flex-fill">
                  <div class="col mx-1 px-0">
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="becData.exam_status===1 || becData.exam_status===2 || becData.exam_status===5">
                      <input type="text" id="becplannercalender" class="bg-white form-control" name="becdate"
                        [(ngModel)]="becData.date_of_exam" (focus)="monthModal.show();switchId=2" required mdbInput
                        mdbValidate>
                      <label for="becplannercalender">Planned Month</label>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="becData.exam_status===3 || becData.exam_status===6">
                      <mdb-date-picker [outlineInput]="true" id="becform2" name="becmydate"
                        class="custom-date-picker-input" [ngClass]="becData.exam_status===4 ? 'custom-width':''"
                        [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="becData.date_of_exam"
                        [placeholder]="'Scheduled date'" required>
                      </mdb-date-picker>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf=" becData.exam_status===4  || becData.exam_status===7">
                      <mdb-date-picker [outlineInput]="true" id="bec2form2" name="bec2mydate"
                        class="custom-date-picker-input" [ngClass]="becData.exam_status===4 ? 'custom-width':''"
                        [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="becData.date_of_exam"
                        [placeholder]="'Exam date'" required>
                      </mdb-date-picker>
                    </div>
                  </div>
                  <div class="col mx-1 px-0" *ngIf="becData.exam_status===4">
                    <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                    [ngClass]="becData.exam_status===4 ? 'custom-width-score':''">
                      <!-- <input mdbInput type="text" id="becscore" class="bg-white form-control" name="becscore"
                        mdbValidate [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbInput
                        [(ngModel)]="becData.score" required>
                      <label for="becscore" class="">Your Score</label> -->
                      <ng-select [items]="scoreArray" name="becscore" [(ngModel)]="becData.score"
                        placeholder="Choose score" required>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
          <div class="mt-4 d-flex align-content-around align-items-center">
            <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-far">

              <form name="farForm" #farForm="ngForm" class="mx-auto w-100">
                <div style="margin: 5px;text-align: center;">Financial Accounting &amp; Reporting</div>
                <div style="margin: 5px;" class="md-form">
                  <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="statusList" name="farstatusList" bindLabel="exam_status_type"
                    [(ngModel)]="farData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                    (change)="farChange()">
                  </ng-select>
                </div>

                <div class="d-flex flex-fill">
                  <div class="col mx-1 px-0">
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="farData.exam_status===1 || farData.exam_status===2 || farData.exam_status===5">
                      <input type="text" id="farplannercalender" class="bg-white form-control" name="fardate"
                        [(ngModel)]="farData.date_of_exam" (focus)="monthModal.show();switchId=3" required mdbInput
                        mdbValidate>
                      <label for="farplannercalender">Planned Month</label>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="farData.exam_status===3 || farData.exam_status===6">
                      <mdb-date-picker [outlineInput]="true" id="farform2" name="farmydate"
                        class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                        [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                        [placeholder]="'Scheduled date'" required>
                      </mdb-date-picker>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="farData.exam_status===4 || farData.exam_status===7">
                      <mdb-date-picker [outlineInput]="true" id="far2form2" name="far2mydate"
                        class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                        [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                        [placeholder]="'Exam date'" required>
                      </mdb-date-picker>
                    </div>
                  </div>
                  <div class="col mx-1 px-0" *ngIf="farData.exam_status===4">
                    <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                    [ngClass]="farData.exam_status===4 ? 'custom-width-score':''">
                      <!-- <input mdbInput type="text" id="farscore" class="bg-white form-control" name="farscore"
                        mdbValidate [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbInput
                        [(ngModel)]="farData.score" required>
                      <label for="farscore" class="">Your Score</label> -->
                      <ng-select [items]="scoreArray" name="farscore" [(ngModel)]="farData.score"
                        placeholder="Choose score" required>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>

        </div>
        <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
          <div class="mt-4 d-flex align-content-around align-items-center">
            <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-reg">


              <form name="regForm" #regForm="ngForm" class="mx-auto w-100">
                <div style="margin: 5px;text-align: center;">Regulation</div>
                <div style="margin: 5px;" class="md-form">
                  <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="statusList" name="regstatusList" bindLabel="exam_status_type"
                    [(ngModel)]="regData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                    (change)="regChange()">
                  </ng-select>
                </div>

                <div class="d-flex flex-fill">
                  <div class="col mx-1 px-0">
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="regData.exam_status===1 || regData.exam_status===2 || regData.exam_status===5">
                      <input type="text" id="regplannercalender" class="bg-white form-control" name="regdate"
                        [(ngModel)]="regData.date_of_exam" (focus)="monthModal.show();switchId=4" required mdbInput
                        mdbValidate>
                      <label for="regplannercalender">Planned Month</label>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="regData.exam_status===3 || regData.exam_status===6">
                      <mdb-date-picker [outlineInput]="true" id="regform2" name="regmydate"
                        class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                        [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                        [placeholder]="'Scheduled date'" required>
                      </mdb-date-picker>
                    </div>
                    <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                      *ngIf="regData.exam_status===4 || regData.exam_status===7">
                      <mdb-date-picker [outlineInput]="true" id="reg2form2" name="reg2mydate"
                        class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                        [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                        [placeholder]="'Exam date'" required>
                      </mdb-date-picker>
                    </div>
                  </div>
                  <div class="col mx-1 px-0" *ngIf="regData.exam_status===4">
                    <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                    [ngClass]="regData.exam_status===4 ? 'custom-width-score':''">
                      <!-- <input mdbInput type="text" id="regscore" class="bg-white form-control" name="regscore"
                        mdbValidate [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbInput
                        [(ngModel)]="regData.score" required>
                      <label for="regscore" class="">Your Score</label> -->
                      <ng-select [items]="scoreArray" name="regscore" [(ngModel)]="regData.score"
                        placeholder="Choose score" required>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
      <!-- <app-eva-confirmation></app-eva-confirmation> -->
    </div>

<!-- <ng-container *ngIf="showEvaluation">

</ng-container> -->


  </div>
  <div class="modal-footer" style="padding: 0px;background: #f0f4f7;"  *ngIf="subjectStatus">
    <button type="button" mdbBtn size="md" color="primary" class="relative waves-light" mdbWavesEffect
      (click)="continueGameplan()">Continue</button>
  </div>
</div>
<div mdbModal #monthModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mymonthModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header p-2" style="background: #f7f7f7;">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
          [ngClass]="year>currentYear?'':'disabled'" (click)="preYear()"><i class="fas fa-angle-left"></i></button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;">{{year}}</button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
          (click)="nextYear()"><i class="fas fa-angle-right"></i></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-3" *ngFor="let row of monthService.month;let i=index" style="padding-left: 5px;">
            <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
              [ngClass]="year===currentYear && currentMonth>i?'disabled':''"
              (click)="year===currentYear && currentMonth>i?'':setDate(row);monthModal.hide();"
              style="background: #efefef !important; box-shadow: none; padding: 10px 5px; border: 1px solid #f5f2f2; width: 50px;">{{row.month}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
