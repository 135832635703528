import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobsPopupComponent } from './jobs-popup.component';
import { ModalModule, WavesModule, InputsModule, ButtonsModule } from 'ng-uikit-pro-standard'


@NgModule({
  declarations: [
    JobsPopupComponent,
  ],
  imports: [
    CommonModule,
    ModalModule, WavesModule, InputsModule, ButtonsModule
  ],
  exports: [JobsPopupComponent],
  entryComponents: [JobsPopupComponent]
})
export class JobsPopupModule { }
